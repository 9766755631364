import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { SearchRequest } from 'types';
import { addParamsToUrl } from 'utils/query';
import {
  providesList,
  cacheByIdArg,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';
import { eventApi, tagType as eventTagType } from '../event';
import { inventoryApi, tagType as inventoryTagType } from '../inventory';

import {
  StockOrder,
  StockOrderProduct,
  EditStockOrderFormValues,
  StockOrderCompleteRequest,
  CreateStockOrderFormValues,
} from './types';

const tagType = 'stock-orders';

const handleStockOrderRequestData = (
  data: EditStockOrderFormValues | CreateStockOrderFormValues
) => {
  if (data.orderTo === 'storage' || data.recipient === 'storage') {
    data.address = null;
    data.contactName = null;
    data.phone = null;
    data.emergencyPhone = null;
  }

  if (data.recipient === 'storage') {
    data.event = null;
  }

  if (data.orderTo === 'terminal' && data.recipient === 'event') {
    data.storageId = null;
  }

  if (data.recipient === 'event') {
    data.eventIds = null;
  }

  delete data.orderTo;
  delete data.recipient;

  return data;
};

export const stockOrdersApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'stockOrdersApi',
  endpoints: (build) => ({
    details: build.query<StockOrder, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.STOCK_ORDER.replace(':id', id),
      }),
    }),

    remove: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.STOCK_ORDER.replace(':id', id),
      }),
    }),

    list: build.query<StockOrder[], SearchRequest | void>({
      providesTags: providesList(tagType),
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.STOCK_ORDERS, { search: params?.search }),
      }),
    }),

    create: build.mutation<void, CreateStockOrderFormValues>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        method: 'post',
        url: URL.STOCK_ORDERS,
        data: handleStockOrderRequestData(data),
      }),
    }),

    edit: build.mutation<void, EditStockOrderFormValues>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        method: 'put',
        data: handleStockOrderRequestData(data),
        url: URL.STOCK_ORDER.replace(':id', String(id)),
      }),
    }),

    changeStatus: build.mutation<
      void,
      Pick<EditStockOrderFormValues, 'id' | 'status'>
    >({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.STOCK_ORDER.replace(':id', String(id)),
      }),
    }),

    complete: build.mutation<void, StockOrderCompleteRequest>({
      invalidatesTags: invalidatesList(tagType),
      query: ({ id }) => ({
        method: 'patch',
        url: URL.STOCK_ORDER_COMPLETE.replace(':id', String(id)),
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            inventoryApi.util.invalidateTags([
              { id: 'LIST', type: inventoryTagType },
            ])
          );

          if (args?.eventId) {
            dispatch(
              eventApi.util.invalidateTags([
                { id: args.eventId, type: eventTagType },
              ])
            );
          }
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useListQuery,
  useEditMutation,
  useDetailsQuery,
  useRemoveMutation,
  useCreateMutation,
  useCompleteMutation,
  useChangeStatusMutation,
} = stockOrdersApi;

export type {
  StockOrder,
  StockOrderProduct,
  EditStockOrderFormValues,
  CreateStockOrderFormValues,
};
