export const titles = [
  'Product',
  'SKU',
  'Supplier',
  'Storage',
  'Cost price',
  'Min price',
  'Sale price',
  '1t order',
  '2d order',
  '',
];
