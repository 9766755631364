import { useState, useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';

import { useHeader } from 'context';
import { ButtonColors } from 'types';
import { mapQueries } from 'utils/query';
import { isMobile, useAlert, useNavigate } from 'hooks';
import { handleFormSubmitWithFiles } from 'utils/helpers';
import { ProductFormValues } from 'store/api/product/types';
import { useListQuery as useSuppliersQuery } from 'store/api/supplier';
import { useListQuery as useCategoriesQuery } from 'store/api/category';
import { useListQuery as useAttributesQuery } from 'store/api/attributes';
import {
  Row,
  Col,
  Button,
  Details,
  FormFooter,
  InputField,
  ButtonTypes,
  SelectField,
  SwitchField,
  CommonStyles,
  SectionHeader,
  FormPhotoFieldRow,
} from 'components';

import * as Styles from './styles';
import { FormProps } from './types';
import { validate, validationSchema } from './config';

export const Form = <T extends ProductFormValues | undefined>({
  onSubmit,
  ...props
}: FormProps<T>) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  const { handleError } = useAlert();

  const formik = useFormik({
    validate,
    validationSchema,
    onSubmit: handleFormSubmitWithFiles({
      onSubmit,
      handleError,
      data: props.data,
      files: [
        {
          formFieldName: 'photo',
          requestFieldName: 'photoUrl',
        },
      ],
    }),
    ...props,
  });
  const { dirty, values, errors, isSubmitting, setFieldTouched } = formik;

  const [tab, setTab] = useState('productInformation');

  const { data: suppliers, ...suppliersQuery } = useSuppliersQuery();
  const { data: attributes, ...attributesQuery } = useAttributesQuery();
  const { data: categories, ...categoriesQuery } = useCategoriesQuery();

  const isEdit = 'id' in props.initialValues;

  useEffect(() => {
    values.attributeIds?.forEach((attributeId) => {
      setFieldTouched(`attributeValueIds.${attributeId}`);
    });
  }, [values.attributeIds]);

  useHeader({
    showBackButton: true,
    pageName: `${isEdit ? 'Edit' : 'New'} product`,
  });

  const categoryOptions =
    categories?.map((category) => ({
      value: category.id,
      label: category.name,
    })) ?? [];

  return (
    <Details
      {...mapQueries([categoriesQuery, suppliersQuery, attributesQuery])}
    >
      <Styles.FormInner>
        <Styles.StyledTabs
          value={tab}
          onChange={setTab}
          items={[
            { to: 'productInformation', label: 'Product Information' },
            // TODO: uncomment when attribute is ready
            // { to: 'attributes', label: 'Custom Attributes' },
          ]}
        />

        <FormikProvider value={formik}>
          <CommonStyles.Form flex={1} $hasMinHeight>
            <Styles.Container>
              {tab === 'productInformation' && (
                <>
                  <FormPhotoFieldRow />

                  <Row>
                    <Col col={3}>
                      <InputField
                        name="name"
                        label="Product Title"
                        placeholder="Add product name"
                      />
                    </Col>

                    <Col col={3}>
                      <SelectField
                        onlyValue
                        label="Supplier"
                        name="supplierId"
                        placeholder="Select supplier"
                        options={
                          suppliers?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          })) ?? []
                        }
                      />
                    </Col>

                    <Col col={3}>
                      <SelectField
                        onlyValue
                        label="Category"
                        name="categoryId"
                        options={categoryOptions}
                        placeholder="Select category"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col col={3}>
                      <InputField name="sku" label="SKU" placeholder="SKU" />
                    </Col>

                    <Col col={3}>
                      <InputField
                        number
                        decimal
                        name="unitWeight"
                        label="Unit Weight"
                        placeholder="Add unit weight"
                      />
                    </Col>

                    <Col col={3}>
                      <InputField
                        number
                        decimal
                        name="unitPerCase"
                        label="Unit Per Case"
                        placeholder="Add unit per case"
                      />
                    </Col>
                  </Row>

                  <SectionHeader title="Price" withBorder={false} />

                  <Row>
                    <Col col={3}>
                      <InputField
                        number
                        decimal
                        name="costPrice"
                        label="Cost Price"
                        placeholder="Add cost price"
                      />
                    </Col>

                    <Col col={3}>
                      <InputField
                        number
                        decimal
                        name="minPrice"
                        label="Min Price"
                        placeholder="Add min price"
                      />
                    </Col>

                    <Col col={3}>
                      <InputField
                        number
                        decimal
                        name="retailPrice"
                        label="Retail Price"
                        placeholder="Add retail price"
                      />
                    </Col>
                  </Row>

                  <SwitchField name="dailyCountable" label="Daily Countable" />
                </>
              )}

              {tab === 'attributes' && (
                <>
                  <Row>
                    <Col col={3}>
                      <SelectField
                        isMulti
                        onlyValue
                        label="Attribute"
                        name="attributeIds"
                        placeholder="Select Attribute"
                        options={
                          attributes?.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          })) ?? []
                        }
                      />
                    </Col>
                  </Row>

                  <SectionHeader title="Added Attributes" />
                  {values.attributeIds?.map((attributeId) => {
                    const attributeData = attributes?.find(
                      ({ id }) => id === attributeId
                    );

                    return (
                      <Row key={attributeId}>
                        <Col>
                          <SelectField
                            isMulti
                            onlyValue
                            label={attributeData?.name}
                            name={`attributeValueIds.${attributeId}`}
                            options={attributeData?.values?.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </>
              )}
            </Styles.Container>

            <FormFooter
              right={
                <CommonStyles.FormButtons>
                  <Button
                    text="Cancel"
                    fullWidth={mobile}
                    onClick={() => navigate(-1)}
                    colorType={ButtonColors.LightBlue}
                  />
                  <Button
                    fullWidth={mobile}
                    type={ButtonTypes.Submit}
                    text={isEdit ? 'Edit' : 'Create'}
                    disabled={!dirty || isSubmitting}
                    onClick={() => {
                      if (errors.name) {
                        setTab('productInformation');
                      }
                    }}
                  />
                </CommonStyles.FormButtons>
              }
            />
          </CommonStyles.Form>
        </FormikProvider>
      </Styles.FormInner>
    </Details>
  );
};
