import { DeltaStatic } from 'quill';
import { AlertOptions } from 'react-alert';
import { ReactNode, RefObject } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { Pathname, NavLinkProps } from 'react-router-dom';

import { Cart } from 'store/api/cart';
import { Event } from 'store/api/event';
import { RoleData } from 'store/api/users';
import { Storage } from 'store/api/storage';
import { Employee } from 'store/api/employees';
import { Product } from 'store/api/product/types';
import {
  Role,
  Roles,
  Feature,
  CommissionType,
  BackOfficeRoles,
  PaymentMethodType,
  PriceApprovalStatus,
  ExpensesPaymentMethod,
} from 'types/enums';

export * from './enums';
export * from './titles';
export * from './colors';

export type { AppTheme } from '../config/themes/types';

export type UnknownObject = Record<string, unknown>;

export type Nullable<T> = T | null | undefined;

export interface RouterNavLinkProps extends NavLinkProps {
  label: string;
  disabled?: boolean;
  badgeCount?: number;
}

export interface LocationState {
  backPath?: Pathname;
}

export interface ForwardRefType<T = unknown> {
  refForward?: (element: T | null) => RefObject<T> | void | null | undefined;
}

export interface IdObject<T = number> {
  id: T;
}

export interface BaseEntity extends IdObject {
  name: string;
}

export interface BasePageProps {
  isError: boolean;
  error?: ReactNode;
  refetch: () => void;
  isLoading?: boolean;
}

export interface UploadFile {
  src: any;
  file: File;
}

export interface DateRange {
  to?: Date;
  from?: Date;
}

export type MessageFormatting = Pick<DeltaStatic, 'ops'>;

export interface UseQueryReturnProps<T = unknown> {
  data?: T;
  isError: boolean;
  isFetching: boolean;
  refetch: () => void;
  error?: Error | SerializedError;
}

export interface SafeRefetchProps {
  isLoading: boolean;
  refetch: () => void;
  isUninitialized: boolean;
}

export interface AuthEmail {
  email: string;
  password: string;
}

export interface BaseResponse<T> {
  data: T;
  message: any;
  success: boolean;
  totaltime_pages: number;
  permission_updated: boolean;
}

export interface PaginationRequest {
  page: number;
  limit?: number;
}

export interface BaseHandleMutationProps<T> {
  error?: any;
  data?: T | void;
  reset?: () => void;
  status?: QueryStatus;
  showError: (error: string) => void;
  onSuccess?: (data?: T | void) => void;
  onError?: (errorMessage: any, error: any) => void;
}

export type HandleMutationProps<T> = Omit<
  BaseHandleMutationProps<T>,
  'showError'
>;

export interface AlertOptionsType extends AlertOptions {
  title?: string;
  icon?: ReactNode;
}

export const NumPads = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '*',
  '0',
  '#',
] as const;
export type NumPadKey = (typeof NumPads)[number];

export enum FileType {
  Image = 'image',
  Video = 'video',
  File = 'file',
}

export interface Media {
  id: number;
  url: string;
  name: string;
  size?: number;
  type: FileType;
  thumbnailUrl?: Nullable<string>;
}

export type TimeZone = {
  id: number;
  name: string;
  code: string;
  time_zone_code: string;
};

export interface PaginationRequest {
  page: number;
  limit?: number;
}

export type PaginationData<K extends string, T> = { count: number } & {
  [key in K]: T[];
};

export interface AuthEmail {
  email: string;
  password: string;
}

export type UserType = 'ops' | 'client';

export interface User extends IdObject {
  name: string;
  email: string;
  role: RoleData;
  photoUrl?: string;
  company?: Company;
  userType: UserType;
  employeeId?: number;
  features: Feature[];
}

export interface Company extends IdObject {
  name: string;
  photoUrl?: string;
  processingFee: number;
}

export interface Person extends BaseEntity {
  zip: string;
  city: string;
  email: string;
  phone: string;
  state: string;
  address: string;
  lastName: string;
  photoUrl?: string;
  firstName: string;
  birthday?: string;
}

export interface SearchRequest {
  search?: string;
}

export const UserTypes: Record<UserType, Role[]> = {
  ops: [Roles.SITE_ADMIN, Roles.BACKOFFICE],
  client: [
    Roles.COMPANY_OWNER,
    BackOfficeRoles.SALES_REP,
    BackOfficeRoles.ADMIN_REP,
    BackOfficeRoles.CUSTOMER_SERVICE_REP,
    BackOfficeRoles.RESEARCH_MANAGER,
    BackOfficeRoles.RESEARCH_REP,
    BackOfficeRoles.ACCOUNTING,
  ],
};

export interface UploadFile {
  src: any;
  file: File;
}

export interface CommonFieldProps {
  name: string;
  label?: string;
  controlErrorLabel?: boolean;
}

export interface Commission {
  id: number;
  user?: User;
  cash?: number;
  amount: number;
  userId: number;
  employeeId: number;
  checkoutId?: number;
  type: CommissionType;
  saleCommission: number;
}

export interface PaymentMethod {
  amount: number;
  last4CC?: number;
  cardType?: string;
  id: string | number;
  holderName?: string;
  checkNumber?: number;
  phoneNumber?: string;
  driverLicense?: string;
  proccessingFee?: number;
  type: PaymentMethodType;
  paymentAuthNumber?: number;
}

export interface PriceApproval extends IdObject {
  cart: Cart;
  user: User;
  event: Event;
  price: number;
  cartId: number;
  userId: number;
  eventId: number;
  comment?: string;
  quantity: number;
  company: Company;
  product: Product;
  storage: Storage;
  storageId: number;
  companyId: number;
  createdAt: string;
  updatedAt: string;
  productId: number;
  status: PriceApprovalStatus;
}

export interface AccountingCategory extends IdObject {
  name: string;
  description?: string;
}

export interface AccountingEvent extends IdObject {
  event: Event;
  amount: number;
  eventId: number;
  percent: number;
  expenseId: number;
}

export interface AccountingExpense extends IdObject {
  amount: number;
  paidDate: string;
  company: Company;
  companyId: number;
  receiptUrl: string;
  paymentDate: string;
  description?: string;
  employees: Employee[];
  events: AccountingEvent[];
  categories: AccountingCategory[];
  paymentMethod: ExpensesPaymentMethod;
}
