import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { addParamsToUrl } from 'utils/query';
import { filterEmptyFields } from 'utils/object';
import {
  cacheByIdArg,
  providesList,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import {
  Customer,
  CustomerData,
  CustomerEditRequest,
  CustomersListRequest,
  CustomerCreateRequest,
} from './types';

const tagType = 'Customers';

export const customersApi = createApi({
  baseQuery: apiQuery,
  tagTypes: [tagType],
  reducerPath: 'customersApi',
  endpoints: (build) => ({
    get: build.query<CustomerData, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.CUSTOMER_DETAILS.replace(':id', id),
      }),
    }),
    remove: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.CUSTOMER_DETAILS.replace(':id', id),
      }),
    }),
    list: build.query<CustomerData[], CustomersListRequest | void>({
      providesTags: providesList(tagType),
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.CUSTOMERS_LIST, {
          search: params?.search,
          employeeId: params?.employeeId,
        }),
      }),
    }),
    create: build.mutation<Customer, CustomerCreateRequest>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        method: 'post',
        url: URL.CUSTOMERS_LIST,
        data: filterEmptyFields({
          ...data,
          shipments: data?.shipments?.filter(
            (shipment) => Object.values(shipment).length
          ).length
            ? JSON.stringify(data.shipments)
            : undefined,
        }),
      }),
    }),
    edit: build.mutation<Customer, CustomerEditRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        method: 'patch',
        url: URL.CUSTOMER_DETAILS.replace(':id', id),
        data: filterEmptyFields({
          ...data,
          shipments: data?.shipments?.filter(
            (shipment) => Object.values(shipment).length
          )?.length
            ? JSON.stringify(data.shipments)
            : undefined,
        }),
      }),
    }),
  }),
});

export const {
  useGetQuery,
  useListQuery,
  useEditMutation,
  useCreateMutation,
  useRemoveMutation,
} = customersApi;

export type { CustomerEditRequest, CustomerCreateRequest };
