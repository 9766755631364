import dayjs from 'dayjs';

import { URL } from 'api/constants';
import { MockHandler } from 'store/query';

import { Employee } from '../employees';
import { Customer } from '../customers/types';

import { AccountingSale } from './types';

const sales = [
  {
    id: 1,
    amount: 100,
    date: dayjs().subtract(5, 'day').toISOString(),
  },
  {
    id: 2,
    amount: 200,
    date: dayjs().subtract(3, 'day').toISOString(),
  },
  {
    id: 3,
    amount: 300,
    date: dayjs().subtract(1, 'day').toISOString(),
  },
  {
    id: 4,
    amount: 400,
    date: dayjs().toISOString(),
  },
];

const employeeSales = sales.map((sale) => ({
  ...sale,
  tip: sale.amount * 0.2,
}));

const mockSalesInfo: AccountingSale = {
  categories: [
    {
      id: 1,
      sold: 100,
      name: 'Food',
      netSales: 1000,
    },
    {
      id: 2,
      sold: 200,
      name: 'Drinks',
      netSales: 2000,
    },
    {
      id: 3,
      sold: 300,
      netSales: 3000,
      name: 'Desserts',
    },
  ],
  customers: [
    {
      id: 1,
      sales,
      customer: {
        id: 1,
        lastName: 'Doe',
        firstName: 'John',
      } as Customer,
    },
    {
      id: 2,
      sales,
      customer: {
        id: 1,
        firstName: 'Jane',
        lastName: 'Webber',
      } as Customer,
    },
  ],
  method: {
    cash: {
      refund: 50,
      amount: 400,
      netSale: 350,
      grossSale: 500,
      transaction: 5,
    },
    check: {
      refund: 20,
      amount: 200,
      netSale: 180,
      grossSale: 300,
      transaction: 2,
    },
    card: {
      refund: 100,
      amount: 1000,
      netSale: 900,
      grossSale: 200,
      transaction: 10,
    },
  },
  employees: [
    {
      id: 1,
      sales: employeeSales,
      employee: {
        id: 1,
        user: {
          name: 'John Doe',
        },
      } as Employee,
    },
    {
      id: 2,
      sales: employeeSales,
      employee: {
        id: 2,
        user: {
          name: 'Jane Webber',
        },
      } as Employee,
    },
    {
      id: 3,
      sales: employeeSales,
      employee: {
        id: 3,
        user: {
          name: 'Will Smith',
        },
      } as Employee,
    },
  ],
  volume: [
    {
      id: 1,
      tax: 170,
      tip: 120,
      refund: 140,
      netSale: 1400,
      discount: 120,
      grossSale: 1700,
      totalAmount: 2200,
      date: dayjs().subtract(5, 'day').toISOString(),
    },
    {
      id: 2,
      tax: 150,
      tip: 100,
      netSale: 1200,
      grossSale: 1500,
      totalAmount: 1000,
      date: dayjs().subtract(3, 'day').toISOString(),
    },
    {
      id: 3,
      tax: 150,
      refund: 120,
      netSale: 1200,
      discount: 100,
      grossSale: 1500,
      totalAmount: 1525,
      date: dayjs().subtract(1, 'day').toISOString(),
    },
    {
      id: 4,
      tax: 160,
      tip: 110,
      netSale: 1300,
      grossSale: 1600,
      totalAmount: 2100,
      date: dayjs().toISOString(),
    },
  ],
};

export const accountingMockApiHandlers: MockHandler[] = [
  {
    method: 'get',
    responseData: mockSalesInfo,
    url: URL.GET_ACCOUNTING_SALES,
  },
];
