import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { SearchRequest } from 'types';
import { addSearchParams } from 'utils/query';
import {
  providesList,
  cacheByIdArg,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';
import {
  tagType as storageInventoryTagType,
  inventoryApi as storageInventoryApi,
} from 'store/api/inventory';

import { apiQuery } from '../../query';
import { eventApi, tagType as eventTagType } from '../event';

import {
  StockTransfer,
  StockTransferType,
  StockTransferStatus,
  StockTransferListType,
  StockTransferFormValues,
  stockTransferTypeOptions,
  stockTransferStatusLabel,
  StockTrnasferAcceptRequest,
  stockTransferStatusOptions,
} from './types';

const tagType = 'transfers';

const handleRequestData = (data: StockTransferFormValues) => {
  if (data.destinationStorageId) {
    data.destinationCompanyId = null;
  }

  if (data.type !== StockTransferType.ClientToClient) {
    delete data.sourceCompanyId;
    delete data.destinationCompanyId;
  }

  delete data.companyTransferType;

  return data;
};

export const stockTransfersApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'stockTransfersApi',
  endpoints: (build) => ({
    details: build.query<StockTransfer, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.STOCK_TRANSFER.replace(':id', id),
      }),
    }),

    remove: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.STOCK_TRANSFER.replace(':id', id),
      }),
    }),

    list: build.query<StockTransfer[], SearchRequest | void>({
      providesTags: providesList(tagType),
      query: (params) => ({
        method: 'get',
        url: addSearchParams(URL.STOCK_TRANSFERS, params),
      }),
    }),

    create: build.mutation<StockTransfer, StockTransferFormValues>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        method: 'post',
        url: URL.STOCK_TRANSFERS,
        data: handleRequestData(data),
      }),
    }),

    edit: build.mutation<void, any>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        method: 'put',
        data: handleRequestData(data),
        url: URL.STOCK_TRANSFER.replace(':id', String(id)),
      }),
    }),

    changeStatus: build.mutation<void, Pick<StockTransfer, 'id' | 'status'>>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.STOCK_TRANSFER.replace(':id', String(id)),
      }),
    }),

    complete: build.mutation<void, number>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'patch',
        url: URL.STOCK_TRANSFER_COMPLETE.replace(':id', String(id)),
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            storageInventoryApi.util.invalidateTags([storageInventoryTagType])
          );
        } catch (error) {
          console.error(error);
        }
      },
    }),

    accept: build.mutation<void, StockTrnasferAcceptRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.STOCK_TRANSFER_ACCEPT.replace(':id', String(id)),
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          if (args?.eventId) {
            dispatch(
              eventApi.util.invalidateTags([
                { id: args.eventId, type: eventTagType },
              ])
            );
          }
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useListQuery,
  useEditMutation,
  useDetailsQuery,
  useRemoveMutation,
  useCreateMutation,
  useAcceptMutation,
  useCompleteMutation,
  useChangeStatusMutation,
} = stockTransfersApi;

export type { StockTransfer, StockTransferListType };
export {
  StockTransferType,
  StockTransferStatus,
  stockTransferTypeOptions,
  stockTransferStatusLabel,
  stockTransferStatusOptions,
};
