import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { SearchRequest } from 'types';
import { addParamsToUrl } from 'utils/query';
import { filterEmptyFields } from 'utils/object';
import { storagesApi, tagType as storagesApiTagType } from 'store/api/storage';
import {
  providesList,
  cacheByIdArg,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import {
  Event,
  EventReport,
  EventStatus,
  EventCheckout,
  EventLocation,
  EventDayResult,
  EventTopSeller,
  EventFormValues,
  CashManagerType,
  ReceiptsRequest,
  EmployeeCheckout,
  DailyCountProduct,
  EventListResponse,
  EventQuestionnaire,
  EventReportRequest,
  ReceiptHTMLRequest,
  EventReportProduct,
  EventReportEmployee,
  EventCheckoutRequest,
  eventPaymentTypeLabel,
  ReceiptDetailsRequest,
  EventEmployeesResponse,
  eventPaymentTypeOptions,
  EmployeeCheckoutRequest,
  EventOpenCloseDayRequest,
  EventCashManagerResponse,
  EventQuestionnaireRequest,
  eventPaymentTransactionLabel,
  eventPaymentTransactionOptions,
} from './types';

const tagType = 'Event';

export const eventApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'eventApi',
  endpoints: (build) => ({
    html: build.query<string, ReceiptHTMLRequest>({
      query: ({ id, receiptId }) => ({
        method: 'get',
        url: generatePath(URL.EVENT_RECEIPTS_HTML, { id, receiptId }),
      }),
    }),

    remove: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.EVENT_DETAILS.replace(':id', id),
      }),
    }),

    checkoutInfo: build.query<EventCheckout, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.EVENT_CHECKOUT.replace(':id', id),
      }),
    }),

    topSellers: build.query<EventTopSeller[], string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.EVENT_TOP_SELLERS.replace(':id', id),
      }),
    }),

    employees: build.query<EventEmployeesResponse[], string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.EVENT_EMPLOYEES.replace(':id', id),
      }),
    }),

    details: build.query<Event, string>({
      keepUnusedDataFor: 0,
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.EVENT_DETAILS.replace(':id', id),
      }),
    }),

    cashManager: build.query<EventCashManagerResponse[], string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.EVENT_CASH_MANAGER.replace(':id', id),
      }),
    }),

    offer: build.mutation<void, Pick<Event, 'id' | 'companyId'>>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.EVENT_OFFER.replace(':id', String(id)),
      }),
    }),

    checkout: build.mutation<EventCheckout, EventCheckoutRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: URL.EVENT_CHECKOUT.replace(':id', String(id)),
      }),
    }),

    openCloseDay: build.mutation<Event, EventOpenCloseDayRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: URL.EVENT_CLOSE_DAY.replace(':id', String(id)),
      }),
    }),

    report: build.query<EventReport[], EventReportRequest>({
      providesTags: [tagType],
      query: ({ id, isDailyReport }) => ({
        method: 'get',
        url: addParamsToUrl(URL.EVENT_REPORT.replace(':id', id), {
          isDailyReport,
        }),
      }),
    }),

    generateReportPdf: build.query<string, EventReportRequest>({
      providesTags: [tagType],
      query: ({ id, isDailyReport }) => ({
        method: 'get',
        url: addParamsToUrl(URL.EVENT_REPORT_PDF.replace(':id', id), {
          isDailyReport,
        }),
      }),
    }),

    questionnaire: build.mutation<
      EventQuestionnaire,
      EventQuestionnaireRequest
    >({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: URL.EVENT_QUESTIONNAIRE.replace(':id', String(id)),
      }),
    }),

    employeeCheckouts: build.query<EmployeeCheckout, EmployeeCheckoutRequest>({
      providesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...params }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.EVENT_EMPLOYEE_CHECKOUTS.replace(':id', String(id)),
          params
        ),
      }),
    }),

    receipts: build.query<EventCheckout[], ReceiptsRequest>({
      providesTags: (result, error, arg) =>
        cacheByIdArg(tagType)(result, error, arg.id),
      query: ({ id, ...query }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.EVENT_RECEIPTS.replace(':id', String(id)),
          query
        ),
      }),
    }),

    list: build.query<Event[], (SearchRequest & Partial<Event>) | void>({
      providesTags: providesList(tagType),
      transformResponse: (response: EventListResponse) => response.data,
      query: (params) => {
        return {
          method: 'get',
          url: params ? addParamsToUrl(URL.EVENT_LIST, params) : URL.EVENT_LIST,
        };
      },
    }),

    receiptDetails: build.query<EventCheckout, ReceiptDetailsRequest>({
      providesTags: (result, error, arg) =>
        cacheByIdArg(tagType)(result, error, arg.id),
      query: ({ id, receiptId }) => ({
        method: 'get',
        url: URL.EVENT_RECEIPT.replace(':id', String(id)).replace(
          ':receiptId',
          receiptId
        ),
      }),
    }),

    create: build.mutation<void, EventFormValues>({
      invalidatesTags: invalidatesList(tagType),
      query: ({ schedule, locations, ...data }) => ({
        method: 'post',
        url: URL.EVENT_LIST,
        data: filterEmptyFields({
          ...data,
          locations: locations.filter(
            (location) => Object.values(location).length
          ),
          schedule: schedule.map(({ date, dayOff, ...rest }) => {
            if (dayOff) {
              return { date, dayOff };
            }

            return { date, dayOff, ...rest };
          }),
        }),
      }),
    }),

    accept: build.mutation<void, Pick<Event, 'id'> & { locationIds: number[] }>(
      {
        invalidatesTags: cacheByIdArgProperty(tagType),
        query: ({ id, locationIds }) => ({
          method: 'patch',
          data: {
            locationIds,
          },
          url: URL.EVENT_ACCEPT.replace(':id', String(id)),
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;

            dispatch(storagesApi.util.invalidateTags([storagesApiTagType]));
          } catch (error) {
            console.error(error);
          }
        },
      }
    ),

    edit: build.mutation<void, { id: string } & Partial<EventFormValues>>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, schedule, locations, companyId, ...data }) => ({
        method: 'patch',
        url: URL.EVENT_DETAILS.replace(':id', id),
        data: filterEmptyFields({
          ...data,
          locations: locations?.filter(
            (location) => Object.values(location).length
          ),
          schedule: schedule?.map(({ date, dayOff, ...rest }) => {
            if (dayOff) {
              return { date, dayOff };
            }

            return { date, dayOff, ...rest };
          }),
        }),
      }),
    }),
  }),
});

export const {
  useListQuery,
  useHtmlQuery,
  useReportQuery,
  useDetailsQuery,
  useEditMutation,
  useOfferMutation,
  useReceiptsQuery,
  useAcceptMutation,
  useRemoveMutation,
  useEmployeesQuery,
  useCreateMutation,
  useTopSellersQuery,
  useCashManagerQuery,
  useCheckoutMutation,
  useCheckoutInfoQuery,
  useReceiptDetailsQuery,
  useOpenCloseDayMutation,
  useQuestionnaireMutation,
  useEmployeeCheckoutsQuery,
  useGenerateReportPdfQuery,
  useLazyGenerateReportPdfQuery,
} = eventApi;

export type {
  Event,
  EventStatus,
  EventReport,
  EventCheckout,
  EventLocation,
  EventDayResult,
  EventTopSeller,
  EventFormValues,
  EmployeeCheckout,
  DailyCountProduct,
  EventQuestionnaire,
  EventReportProduct,
  EventReportEmployee,
};
export {
  tagType,
  CashManagerType,
  eventPaymentTypeLabel,
  eventPaymentTypeOptions,
  eventPaymentTransactionLabel,
  eventPaymentTransactionOptions,
};
