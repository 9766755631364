import { useState } from 'react';

import { FileType } from 'types';
import { Image } from 'components/image';
import { Gallery } from 'components/gallery';
import { PaymentDetails } from 'components/payment-details';

import { ReimbursementDetailsProps } from './types';

export const ReimbursementDetails = ({
  data,
  title,
  ...props
}: ReimbursementDetailsProps) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <PaymentDetails
      data={data}
      title={title || 'Reimbursement'}
      {...props}
      extendsSections={{
        col: 6,
        title: 'Receipt',
        value: (
          <Image
            width={144}
            height={144}
            objectFit="cover"
            alt="Receipt photo"
            src={data?.receiptUrl}
            onClick={() => setOpen(true)}
          />
        ),
      }}
    >
      {data?.receiptUrl && (
        <Gallery
          open={isOpen}
          hideFooterButton
          close={() => setOpen(false)}
          files={[
            {
              id: 1,
              url: data.receiptUrl,
              type: FileType.Image,
              name: 'Receipt photo',
            },
          ]}
        />
      )}
    </PaymentDetails>
  );
};
