import { useState } from 'react';

import { ButtonColors } from 'types';
import { joinStrings } from 'utils/helpers';
import { isMobile, isDesktop } from 'hooks';
import { useLazyGenerateReportPdfQuery } from 'store/api/event';
import { Icon, Flex, Button, ButtonProps, CloseButton } from 'components';

import { EventHeaderProps } from './types';

export const EventHeader = ({
  date,
  title,
  event,
  onClose,
  isDailyReport,
}: EventHeaderProps) => {
  const mobile = isMobile();
  const desktop = isDesktop();
  const [pdfUrl, setPdfUrl] = useState<string>();

  const [trigger, { isLoading }] = useLazyGenerateReportPdfQuery();

  const headerButtons: ButtonProps[] = [
    { disabled: true, text: 'Add notes', icon: <Icon.Edit /> },
    { disabled: true, text: 'Export', icon: <Icon.Share /> },
    {
      text: 'Save PDF',
      disabled: isLoading,
      icon: <Icon.Download />,
      onClick: async () => {
        if (pdfUrl) {
          window.open(pdfUrl, '_blank');
        } else {
          const response = await trigger({
            isDailyReport,
            id: String(event.id),
          }).unwrap();
          setPdfUrl(response);
          window.open(response, '_blank');
        }
      },
    },
  ];

  return (
    <Flex
      gap={24}
      justifyContent="space-between"
      flexDirection={desktop ? 'row' : 'column'}
    >
      <Flex
        gap={mobile ? 8 : 24}
        flexDirection={mobile ? 'column' : 'row'}
        alignItems={mobile ? undefined : 'center'}
      >
        <h2>{title}</h2>
        <Flex flexDirection="column">
          <p>Date</p>
          <h3>{date}</h3>
        </Flex>
        <Flex flexDirection="column">
          <p>Location</p>
          <h3>
            {joinStrings(
              event.locations.map((value) => value.name),
              ', '
            )}
          </h3>
        </Flex>
      </Flex>

      <Flex gap={16}>
        {headerButtons.map((button, i) => (
          <Button
            key={i}
            transparent
            padding="0"
            colorType={ButtonColors.LightBlue}
            {...button}
            text={mobile ? undefined : button.text}
            tooltip={mobile ? button.text : undefined}
          />
        ))}
        {onClose && <CloseButton onClose={onClose} />}
      </Flex>
    </Flex>
  );
};
