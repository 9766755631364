import dayjs from 'dayjs';

import { useNavigate } from 'hooks';
import { mapQuery } from 'utils/query';
import { Flex } from 'components/flex';
import { Info } from 'components/info';
import { Row, Col } from 'components/grid';
import { formatPhone } from 'utils/helpers';
import { InfoBlock } from 'components/info-block';
import { GeneralInfo } from 'components/general-info';
import { DeleteDialog } from 'components/delete-dialog';
import { Details as DetailsComponent } from 'components/details';
import { useGetQuery, useRemoveMutation } from 'store/api/customers';

export const Details = ({
  id,
  navigateUrlAfterDelete,
}: {
  id: string | undefined;
  navigateUrlAfterDelete: string;
}) => {
  const navigate = useNavigate();

  const { data, ...props } = useGetQuery(id!, { skip: !id });
  const [remove, mutation] = useRemoveMutation();

  const name = data && `${data.person.firstName} ${data.person.lastName}`;

  return (
    <DetailsComponent {...mapQuery(props)}>
      {data && (
        <GeneralInfo
          name={name}
          photoSize={128}
          withPlaceholders
          stickyWithHeader={false}
          photo={data.person.photoUrl}
          onEdit={() => navigate('edit')}
          rightSlot={
            <DeleteDialog
              name={name!}
              id={data.id}
              action={remove}
              mutation={mutation}
              title="Delete customer"
              navigateUrl={navigateUrlAfterDelete}
            />
          }
          sections={[
            {
              rows: [
                [
                  {
                    col: 4,
                    title: 'Phone number',
                    value: formatPhone(data.person.phone),
                  },
                  {
                    col: 4,
                    title: 'Email',
                    value: data.person.email,
                  },
                  {
                    col: 4,
                    title: 'Birthday',
                    value: dayjs(data.person.birthday).format('LL'),
                  },
                ],
                [
                  {
                    col: 4,
                    colTablet: 6,
                    title: 'Address',
                    value: data.person.address,
                  },
                ],
              ],
            },
            ...(data.shipments.length > 0
              ? [
                  {
                    title: 'Shipment info',
                    children: (
                      <Flex column gap={16} style={{ paddingBottom: '24px' }}>
                        {data.shipments.map((shipment) => (
                          <InfoBlock key={shipment.id}>
                            <Row>
                              <Col col={3}>
                                <Info
                                  title="Address"
                                  value={shipment.address}
                                />
                              </Col>
                              <Col col={3}>
                                <Info
                                  title="Contact Name"
                                  value={shipment.contactName}
                                />
                              </Col>
                              <Col col={3}>
                                <Info
                                  title="Phone"
                                  value={formatPhone(shipment.phone)}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col col={3}>
                                <Info
                                  title="Emergency Phone"
                                  value={formatPhone(shipment.emergencyPhone)}
                                />
                              </Col>

                              <Col col={3}>
                                <Info title="Email" value={shipment.email} />
                              </Col>
                            </Row>
                          </InfoBlock>
                        ))}
                      </Flex>
                    ),
                  },
                ]
              : []),
            {
              title: 'Registered info',
              rows: [
                [
                  {
                    col: 4,
                    title: 'Employee',
                    value: data.employee.name,
                  },
                  {
                    col: 4,
                    title: 'Registered',
                    value: data.registered,
                  },
                  {
                    col: 4,
                    title: 'Event',
                    value: data.event?.name,
                  },
                ],
              ],
            },
          ]}
        />
      )}
    </DetailsComponent>
  );
};
