import { Formik, FieldArray } from 'formik';

import { useHeader } from 'context';
import { ButtonColors } from 'types';
import { isMobile, useNavigate } from 'hooks';
import { Supplier } from 'store/api/supplier/types';
import {
  Row,
  Col,
  Flex,
  Button,
  AddButton,
  InfoBlock,
  FormFooter,
  InputField,
  ButtonTypes,
  DeleteButton,
  CommonStyles,
  SectionHeader,
  StatesSelectField,
  AddressAutoCompleteInputField,
} from 'components';

import { FormProps } from './types';
import { validationSchema } from './config';

export const Form = <T extends Supplier | undefined>(props: FormProps<T>) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  const isEdit = 'id' in props.initialValues;

  useHeader({
    showBackButton: true,
    pageName: `${isEdit ? 'Edit' : 'New'} supplier`,
  });

  return (
    <Formik {...props} validationSchema={validationSchema}>
      {({ dirty, values, isSubmitting }) => {
        return (
          <CommonStyles.Form>
            <CommonStyles.FormInner>
              <SectionHeader withBorder={false} title="Supplier information" />

              <Row>
                <Col col={3}>
                  <InputField
                    name="name"
                    label="Supplier Name"
                    placeholder="Add supplier name"
                  />
                </Col>
              </Row>

              <Row>
                <Col col={2}>
                  <AddressAutoCompleteInputField zipFieldName="zip" />
                </Col>

                <Col col={2}>
                  <StatesSelectField name="state" />
                </Col>

                <Col col={2}>
                  <InputField number name="zip" label="ZIP" placeholder="ZIP" />
                </Col>

                <Col col={2}>
                  <InputField name="city" label="City" placeholder="City" />
                </Col>
              </Row>

              <SectionHeader withBorder={false} title="Contact info" />
              <Row>
                <Col col={2}>
                  <InputField
                    name="contactName"
                    label="Contact Name"
                    placeholder="Contact Name"
                  />
                </Col>

                <Col col={2}>
                  <InputField
                    name="contactEmail"
                    label="Contact Email"
                    placeholder="Contact Email"
                  />
                </Col>
              </Row>

              <Row>
                <Col col={2}>
                  <InputField
                    type="tel"
                    name="contactMobile"
                    label="Contact Mobile"
                    placeholder="Contact Mobile"
                  />
                </Col>

                <Col col={2}>
                  <InputField
                    type="tel"
                    name="contactLandline"
                    label="Contact Landline"
                    placeholder="Contact Landline"
                  />
                </Col>

                <Col col={2}>
                  <InputField
                    type="tel"
                    name="contactFax"
                    label="Contact Fax"
                    placeholder="Contact Fax"
                  />
                </Col>
              </Row>

              <SectionHeader title="Warehouses" withBorder={false} />

              <FieldArray
                name="warehouses"
                render={({ push, remove }) => (
                  <Flex column gap={16}>
                    {values.warehouses.map((item, index) => (
                      <InfoBlock key={index}>
                        <Row>
                          <Col col={3}>
                            <InputField
                              placeholder="Warehouse name"
                              name={`warehouses[${index}].name`}
                              label={`Warehouse ${index + 1} Name`}
                            />
                          </Col>
                          <Col col={3}>
                            <InputField
                              type="tel"
                              label="Phone"
                              placeholder="Phone"
                              name={`warehouses[${index}].phone`}
                            />
                          </Col>
                          <Col col={3}>
                            <InputField
                              type="tel"
                              label="Emergency phone"
                              placeholder="Emergency phone"
                              name={`warehouses[${index}].emergencyPhone`}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col col={2}>
                            <InputField
                              label="Address"
                              placeholder="Address"
                              name={`warehouses[${index}].address`}
                            />
                          </Col>

                          <Col col={2}>
                            <StatesSelectField
                              name={`warehouses[${index}].state`}
                            />
                          </Col>

                          <Col col={2}>
                            <InputField
                              number
                              label="ZIP"
                              placeholder="ZIP"
                              name={`warehouses[${index}].zip`}
                            />
                          </Col>
                          <Col col={2}>
                            <InputField
                              label="City"
                              placeholder="City"
                              name={`warehouses[${index}].city`}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <DeleteButton
                              text="Remove warehouse"
                              onClick={() => remove(index)}
                            />
                          </Col>
                        </Row>
                      </InfoBlock>
                    ))}

                    <AddButton
                      text="Add warehouse"
                      onClick={() => push({ name: '' })}
                    />
                  </Flex>
                )}
              />
            </CommonStyles.FormInner>

            <FormFooter
              right={
                <CommonStyles.FormButtons>
                  <Button
                    text="Cancel"
                    fullWidth={mobile}
                    onClick={() => navigate(-1)}
                    colorType={ButtonColors.LightBlue}
                  />
                  <Button
                    fullWidth={mobile}
                    type={ButtonTypes.Submit}
                    text={isEdit ? 'Edit' : 'Create'}
                    disabled={!dirty || isSubmitting}
                  />
                </CommonStyles.FormButtons>
              }
            />
          </CommonStyles.Form>
        );
      }}
    </Formik>
  );
};
