import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';

import { Commission } from 'types';
import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { addParamsToUrl } from 'utils/query';

import { eventApi, tagType as eventTagType } from '../event';

import {
  Analytics,
  AnalyticsSales,
  AnalyticsProduct,
  AnalyticsEmployee,
  AnalyticsLocation,
  GetAnalyticsRequest,
  EditTransactionRequest,
  AnalyticsPaymentMethod,
  AnalyticsEmployeeDetails,
  GetAnalyticsEmployeeRequest,
} from './types';

const tagType = 'analytics';

export const analyticsApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'analyticsApi',
  endpoints: (build) => ({
    get: build.query<Analytics, GetAnalyticsRequest>({
      providesTags: [tagType],
      query: (query) => ({
        method: 'get',
        url: addParamsToUrl(URL.GET_ANALYCITS, query),
      }),
    }),

    employee: build.query<
      AnalyticsEmployeeDetails,
      GetAnalyticsEmployeeRequest
    >({
      keepUnusedDataFor: 1,
      providesTags: [tagType],
      query: ({ id, ...query }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.GET_ANALYCITS_EMPLOYEE.replace(':id', String(id)),
          query
        ),
      }),
    }),

    editTransaction: build.mutation<Commission[], EditTransactionRequest>({
      invalidatesTags: [tagType],
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: generatePath(URL.GET_ANALYCITS_CHECKOUT_EDIT, { id }),
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            eventApi.util.invalidateTags([
              { id: args.eventId, type: eventTagType },
            ])
          );
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const { useGetQuery, useEmployeeQuery, useEditTransactionMutation } =
  analyticsApi;
export type {
  AnalyticsSales,
  AnalyticsProduct,
  AnalyticsEmployee,
  AnalyticsLocation,
  AnalyticsPaymentMethod,
  AnalyticsEmployeeDetails,
};
