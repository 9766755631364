import { AppRoutes } from 'config';
import { useHeader } from 'context';
import { Customers } from 'components';

export const Create = () => {
  useHeader({
    showBackButton: true,
    pageName: 'New customer',
  });

  return <Customers.Create navigateUrl={AppRoutes.Customers.Home} />;
};
