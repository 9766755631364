import React, { ReactNode } from 'react';
import { Navigate, NonIndexRouteObject } from 'react-router-dom';

import { Icon } from 'components/icon';
import Shipments from 'pages/shipments';
import { Placeholder } from 'components/placeholder';
import { Role, Roles, Feature, BackOfficeRoles } from 'types';
import {
  Users,
  Events,
  Themes,
  Devices,
  Company,
  Reports,
  Profile,
  Settings,
  Receipts,
  Products,
  Category,
  Storages,
  Carriers,
  Shipping,
  Dashboard,
  Customers,
  Inventory,
  Employees,
  Suppliers,
  Accounting,
  StockOrders,
  Permissions,
  EventOrders,
  DailyCounts,
  CustomerCare,
  ProductTypes,
  Integrations,
  BusinessInfo,
  Notifications,
  EventInventory,
  StockTransfers,
  PaymentMethods,
  InventoryProducts,
} from 'pages';

import { Config } from './env';
import { AppRoutes } from './routes';

export interface RouteObject
  extends Omit<NonIndexRouteObject, 'index' | 'children'> {
  title?: string;
  index?: boolean;
  icon?: ReactNode;
  features?: Feature[];
  hideForRoles?: Role[];
  hideInSidebar?: boolean;
  children?: RouteObject[];
}

const getCommonProfileRoutes = (isEmployee?: boolean) => [
  {
    index: true,
    element: <Navigate replace to={AppRoutes.Profile.Payments} />,
  },
  {
    path: AppRoutes.Profile.Payments,
    children: [
      { index: true, element: <Profile.Payments /> },
      {
        element: <Profile.CreateReimbursements />,
        path: AppRoutes.Profile.Reimbursement.New,
      },
      {
        element: <Profile.EditReimbursements />,
        path: AppRoutes.Profile.Reimbursement.Edit,
      },
      {
        element: <Profile.ReimbursementDetails />,
        path: AppRoutes.Profile.Reimbursement.Details,
      },

      {
        element: <Profile.CreateAdvances />,
        path: AppRoutes.Profile.Advances.New,
      },
      {
        element: <Profile.AdvanceDetails />,
        path: AppRoutes.Profile.Advances.Details,
      },
      {
        element: <Profile.EditAdvances />,
        path: AppRoutes.Profile.Advances.Edit,
      },

      {
        element: <Profile.BonusDetails />,
        path: AppRoutes.Profile.Bonuses.Details,
      },
      {
        element: <Profile.CreateBonuses />,
        path: AppRoutes.Profile.Bonuses.New,
      },
      {
        element: <Profile.EditBonuses />,
        path: AppRoutes.Profile.Bonuses.Edit,
      },
    ],
  },
  {
    path: AppRoutes.Profile.Customers,
    element: <Profile.Customers isEmployee={isEmployee} />,
  },
  {
    path: AppRoutes.Profile.Sales,
    element: <Profile.SalesList />,
  },
  {
    element: <Profile.ManageAppointments />,
    path: AppRoutes.Profile.ManageAppointments,
  },
  {
    element: <Profile.Documents />,
    path: AppRoutes.Profile.Documents,
  },
];

export const getRouteObjects = (userFeatures?: Feature[]): RouteObject[] => {
  const hasAccessToAnalytics = userFeatures?.includes(Feature.ANALYTICS);

  return [
    {
      icon: <Icon.User />,
      title: 'User Profile',
      element: <Profile.Home />,
      path: AppRoutes.Profile.Home,
      features: [Feature.USER_PROFILE],
      children: getCommonProfileRoutes(),
    },
    {
      title: 'Dashboard',
      element: <Dashboard.Home />,
      icon: <Icon.ActionCenter />,
      features: [Feature.DASHBOARD],
      path: AppRoutes.Dashboard.Home,
      children: [
        {
          index: true,
          element: (
            <Navigate
              replace
              to={
                hasAccessToAnalytics
                  ? AppRoutes.Dashboard.Analytics.Home
                  : AppRoutes.Dashboard.Approval.Home
              }
            />
          ),
        },
        {
          path: AppRoutes.Dashboard.Analytics.Home,
          hideForRoles: [...Object.values(BackOfficeRoles), Roles.BACKOFFICE],
          children: [
            { index: true, element: <Dashboard.Analytics /> },
            {
              element: <Dashboard.EditTransaction />,
              path: AppRoutes.Dashboard.Analytics.EditTransaction,
            },
          ],
        },
        {
          hideForRoles: [Roles.SITE_ADMIN],
          path: AppRoutes.Dashboard.Approval.Home,
          children: [
            { index: true, element: <Dashboard.ApprovalList /> },
            {
              element: <Dashboard.ApprovalDetails />,
              path: AppRoutes.Dashboard.Approval.Details,
            },
          ],
        },
        {
          features: [Feature.PRICE_APPROVAL],
          path: AppRoutes.Dashboard.PriceApproval.Home,
          children: [
            { index: true, element: <Dashboard.PriceApprovalList /> },
            {
              element: <Dashboard.PriceApprovalDetails />,
              path: AppRoutes.Dashboard.PriceApproval.Details,
            },
          ],
        },
      ],
    },
    {
      title: 'Companies',
      icon: <Icon.UserDual />,
      path: AppRoutes.Company.Home,
      features: [Feature.COMPANIES],
      hideForRoles: [Roles.COMPANY_OWNER],
      children: [
        {
          index: true,
          element: <Company.List />,
        },
        {
          path: AppRoutes.Company.New,
          element: <Company.Create />,
        },
        {
          element: <Company.Edit />,
          path: AppRoutes.Company.Edit,
        },
        {
          element: <Company.Details />,
          path: AppRoutes.Company.Details,
        },
      ],
    },
    {
      title: 'Accounting',
      icon: <Icon.Folder />,
      element: <Accounting.Home />,
      features: [Feature.ACCOUNTING],
      path: AppRoutes.Accounting.Home,
      children: [
        {
          index: true,
          element: <Navigate replace to={AppRoutes.Accounting.Expenses.Home} />,
        },
        {
          path: AppRoutes.Accounting.Expenses.Home,
          children: [
            {
              index: true,
              element: <Accounting.Expenses />,
            },
            {
              element: <Accounting.CreateExpenses />,
              path: AppRoutes.Accounting.Expenses.New,
            },
            {
              element: <Accounting.EditExpenses />,
              path: AppRoutes.Accounting.Expenses.Edit,
            },
            {
              element: <Accounting.ExpensesDetails />,
              path: AppRoutes.Accounting.Expenses.Details,
            },
            {
              element: <Accounting.ExpensesCategories />,
              path: AppRoutes.Accounting.Expenses.Categories,
            },
          ],
        },
        {
          element: <Accounting.Deposit />,
          path: AppRoutes.Accounting.Deposit.Home,
        },
        {
          element: <Accounting.Sales />,
          path: AppRoutes.Accounting.Sales.Home,
        },
        {
          element: <Accounting.Transactions />,
          path: AppRoutes.Accounting.Transactions.Home,
        },
        {
          element: <Accounting.Chargebacks />,
          path: AppRoutes.Accounting.Chargebacks.Home,
        },
        {
          element: <Accounting.GiftCards />,
          path: AppRoutes.Accounting.GiftCards.Home,
        },
        {
          element: <Accounting.Tavla.Home />,
          path: AppRoutes.Accounting.Tavla.Home,
          children: [
            {
              index: true,
              element: (
                <Navigate replace to={AppRoutes.Accounting.Tavla.Summary} />
              ),
            },
            {
              element: <Accounting.Tavla.Summary />,
              path: AppRoutes.Accounting.Tavla.Summary,
            },
            {
              element: <Accounting.Tavla.BackOffice />,
              path: AppRoutes.Accounting.Tavla.BackOffice,
            },
            {
              element: <Accounting.Tavla.Transactions />,
              path: AppRoutes.Accounting.Tavla.Transactions,
            },
            {
              element: <Accounting.Tavla.Payments />,
              path: AppRoutes.Accounting.Tavla.Payments,
            },
          ],
        },
        {
          element: <Accounting.Revenue.Home />,
          path: AppRoutes.Accounting.Revenue.Home,
          children: [
            {
              index: true,
              element: (
                <Navigate replace to={AppRoutes.Accounting.Revenue.Deposit} />
              ),
            },
            {
              element: <Accounting.Revenue.Deposit />,
              path: AppRoutes.Accounting.Revenue.Deposit,
            },
            {
              element: <Accounting.Revenue.Summary />,
              path: AppRoutes.Accounting.Revenue.Summary,
            },
            {
              element: <Accounting.Revenue.Credit />,
              path: AppRoutes.Accounting.Revenue.Credit,
            },
          ],
        },
        {
          element: <Accounting.SalesTax.Home />,
          path: AppRoutes.Accounting.SalesTax.Home,
          children: [
            {
              index: true,
              element: (
                <Navigate replace to={AppRoutes.Accounting.SalesTax.Taxes} />
              ),
            },
            {
              element: <Accounting.SalesTax.Taxes />,
              path: AppRoutes.Accounting.SalesTax.Taxes,
            },
            {
              element: <Accounting.SalesTax.Summary />,
              path: AppRoutes.Accounting.SalesTax.Summary,
            },
          ],
        },
        {
          element: <Accounting.ProfitAndLosses />,
          path: AppRoutes.Accounting.ProfitAndLosses.Home,
        },
      ],
    },
    {
      icon: <Icon.Case />,
      title: 'Customer care',
      element: <CustomerCare />,
      path: AppRoutes.CustomerCare.Home,
      features: [Feature.CUSTOMER_SUPPORT],
    },
    {
      title: 'Customers',
      icon: <Icon.UserDual />,
      path: AppRoutes.Customers.Home,
      features: [Feature.CUSTOMER_SUPPORT, Feature.CUSTOMERS, Feature.SALES],
      children: [
        {
          index: true,
          element: <Customers.List />,
        },
        {
          path: AppRoutes.Customers.New,
          element: <Customers.Create />,
        },
        {
          element: <Customers.Edit />,
          path: AppRoutes.Customers.Edit,
        },
        {
          element: <Customers.Details />,
          path: AppRoutes.Customers.Details,
        },
      ],
    },
    {
      title: 'Reports',
      element: <Reports />,
      icon: <Icon.FileInfo />,
      features: [Feature.REPORTS],
      path: AppRoutes.Reports.Home,
    },
    {
      title: 'Events',
      icon: <Icon.List />,
      features: [Feature.EVENTS],
      path: AppRoutes.Events.Home,
      children: [
        {
          index: true,
          element: <Events.List />,
        },
        {
          element: <Events.Create />,
          path: AppRoutes.Events.New,
        },
        {
          element: <Events.Edit />,
          path: AppRoutes.Events.Edit,
        },
        {
          element: <Events.Details />,
          path: AppRoutes.Events.Details,
        },
      ],
    },
    {
      title: 'Inventory',
      element: <Inventory />,
      icon: <Icon.SecondaryList />,
      features: [Feature.INVENTORY],
      path: AppRoutes.Inventory.Home,
      children: [
        {
          index: true,
          element: <Navigate replace to={AppRoutes.Inventory.Products.Home} />,
        },
        {
          title: 'Products',
          icon: <Icon.Barcode />,
          path: AppRoutes.Inventory.Products.Home,
          children: [
            {
              index: true,
              element: <InventoryProducts.List />,
            },
            {
              element: <InventoryProducts.Create />,
              path: AppRoutes.Inventory.Products.New,
            },
            {
              element: <InventoryProducts.Details />,
              path: AppRoutes.Inventory.Products.Details,
            },
            {
              element: <InventoryProducts.Edit />,
              path: AppRoutes.Inventory.Products.Edit,
            },
          ],
        },
        {
          title: 'Suppliers',
          icon: <Icon.UserFilled />,
          path: AppRoutes.Inventory.Suppliers.Home,
          children: [
            {
              index: true,
              element: <Suppliers.List />,
            },
            {
              element: <Suppliers.Create />,
              path: AppRoutes.Inventory.Suppliers.New,
            },
            {
              element: <Suppliers.Details />,
              path: AppRoutes.Inventory.Suppliers.Details,
            },
            {
              element: <Suppliers.Edit />,
              path: AppRoutes.Inventory.Suppliers.Edit,
            },
          ],
        },
        {
          title: 'Category',
          icon: <Icon.LongText />,
          path: AppRoutes.Inventory.Category.Home,
          children: [
            { index: true, element: <Category.List /> },
            {
              element: <Category.Create />,
              path: AppRoutes.Inventory.Category.New,
            },
            {
              element: <Category.Edit />,
              path: AppRoutes.Inventory.Category.Edit,
            },
          ],
        },
        {
          title: 'Storages',
          icon: <Icon.Storage />,
          path: AppRoutes.Inventory.Storages.Home,
          children: [
            {
              index: true,
              element: <Storages.List />,
            },
            {
              element: <Storages.Create />,
              path: AppRoutes.Inventory.Storages.New,
            },
            {
              element: <Storages.Edit />,
              path: AppRoutes.Inventory.Storages.Edit,
            },
            {
              element: <Storages.Details />,
              path: AppRoutes.Inventory.Storages.Details.Home,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      replace
                      to={AppRoutes.Inventory.Storages.Details.General}
                    />
                  ),
                },
                {
                  element: <Storages.General />,
                  path: AppRoutes.Inventory.Storages.Details.General,
                },
                {
                  path: AppRoutes.Inventory.Storages.Details.Inventory,
                  children: [
                    { index: true, element: <Storages.Inventory /> },
                    {
                      element: <Storages.InventoryDetails />,
                      path: AppRoutes.Inventory.Storages.Details
                        .InventoryDetails,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Transfers',
          path: AppRoutes.Inventory.Transfers.Home,
          children: [
            {
              index: true,
              element: <StockTransfers.List />,
            },
            {
              element: <StockTransfers.Create />,
              path: AppRoutes.Inventory.Storages.New,
            },
            {
              element: <StockTransfers.Edit />,
              path: AppRoutes.Inventory.Storages.Edit,
            },
            {
              element: <StockTransfers.Details />,
              path: AppRoutes.Inventory.Storages.Details.Home,
            },
          ],
        },
        {
          title: 'Event Inventory',
          path: AppRoutes.Inventory.Event.Home,
          children: [
            {
              index: true,
              element: <EventInventory.List />,
            },
            {
              path: AppRoutes.Inventory.Event.Details.Home,
              children: [
                {
                  index: true,
                  element: <EventInventory.Details />,
                },
                {
                  element: <EventInventory.InventoryDetails />,
                  path: AppRoutes.Inventory.Event.Details.InventoryDetails,
                },
              ],
            },
          ],
        },
        {
          title: 'Daily Counts',
          element: <DailyCounts.List />,
          path: AppRoutes.Inventory.DailyCounts.Home,
        },
        // TODO: uncomment when it's ready
        // {
        //   title: 'Deals',
        //   icon: <Icon.Deal />,
        //   path: AppRoutes.Inventory.Deals.Home,
        //   children: [
        //     {
        //       index: true,
        //       element: <Deals.List />,
        //     },
        //     {
        //       element: <Deals.Create />,
        //       path: AppRoutes.Inventory.Deals.New,
        //     },
        //     {
        //       element: <Deals.Edit />,
        //       path: AppRoutes.Inventory.Deals.Edit,
        //     },
        //   ],
        // },
        // {
        //   title: 'Attributes',
        //   icon: <Icon.Deal />,
        //   path: AppRoutes.Inventory.Attributes.Home,
        //   children: [
        //     {
        //       index: true,
        //       element: <Attributes.List />,
        //     },
        //     {
        //       element: <Attributes.Create />,
        //       path: AppRoutes.Inventory.Attributes.New,
        //     },
        //     {
        //       element: <Attributes.Edit />,
        //       path: AppRoutes.Inventory.Attributes.Edit,
        //     },
        //   ],
        // },
      ],
    },
    {
      title: 'Products',
      hideInSidebar: true,
      icon: <Icon.Folder />,
      features: [Feature.EVENTS],
      path: AppRoutes.Products.Home,
      hideForRoles: [Roles.COMPANY_OWNER],
      children: [
        {
          index: true,
          element: <Products.List />,
        },
        { element: <Products.CheckOut />, path: AppRoutes.Products.CheckOut },
        {
          element: <Products.Employees />,
          path: AppRoutes.Products.Employees,
        },
        {
          element: <Products.Questionnare />,
          path: AppRoutes.Products.Questionnare,
        },
        {
          element: <Products.CashManager />,
          path: AppRoutes.Products.CashManager,
        },
        {
          element: <Products.Customers />,
          path: AppRoutes.Products.Customers.Home,
          children: [
            {
              index: true,
              element: <Placeholder text="Please select a customer" />,
            },
            {
              element: <Products.CustomerDetails />,
              path: AppRoutes.Products.Customers.Details,
            },
            {
              element: <Products.CustomerEdit />,
              path: AppRoutes.Products.Customers.Edit,
            },
            {
              element: <Products.CustomerNew />,
              path: AppRoutes.Products.Customers.New,
            },
          ],
        },
        {
          element: <Products.Receipts />,
          path: AppRoutes.Products.Receipts.Home,
          children: [
            {
              index: true,
              element: <Placeholder text="Please select a receipt" />,
            },
            {
              element: <Products.Exchange />,
              path: AppRoutes.Products.Receipts.Exchange,
            },
            {
              path: AppRoutes.Products.Receipts.Details,
              children: [
                {
                  index: true,
                  element: <Products.ReceiptDetails />,
                },
                Config.isDebugHTMLEnabled
                  ? {
                      element: <Products.ReceiptHTML />,
                      path: AppRoutes.Products.Receipts.HTML,
                    }
                  : {},
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Employees',
      icon: <Icon.GroupFilled />,
      features: [Feature.EMPLOYEES],
      path: AppRoutes.Employees.Home,
      hideForRoles: [BackOfficeRoles.SALES_REP, BackOfficeRoles.MANAGER],
      children: [
        {
          index: true,
          element: <Employees.List />,
        },
        {
          element: <Employees.Create />,
          path: AppRoutes.Employees.New,
        },
        {
          element: <Employees.Edit />,
          path: AppRoutes.Employees.Edit,
        },
        {
          path: AppRoutes.Employees.Details,
          element: <Profile.Home isEmployee />,
          children: getCommonProfileRoutes(true),
        },
      ],
    },
    {
      title: 'Shipments',
      element: <Shipments />,
      icon: <Icon.Transfer />,
      features: [Feature.SHIPMENTS],
      path: AppRoutes.Shipments.Home,
      children: [
        {
          index: true,
          element: (
            <Navigate
              replace
              to={AppRoutes.Shipments.Children.StockOrders.Home}
            />
          ),
        },
        {
          path: AppRoutes.Shipments.Children.EventOrders.Home,
          children: [
            {
              index: true,
              element: <EventOrders.List />,
            },
          ],
        },
        {
          path: AppRoutes.Shipments.Children.StockOrders.Home,
          children: [
            {
              index: true,
              element: <StockOrders.List />,
            },
            {
              element: <StockOrders.Create />,
              path: AppRoutes.Shipments.Children.StockOrders.New.Home,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      replace
                      to={
                        AppRoutes.Shipments.Children.StockOrders.New.Children
                          .OrderInfo
                      }
                    />
                  ),
                },
                {
                  element: <StockOrders.OrderInfo />,
                  path: AppRoutes.Shipments.Children.StockOrders.New.Children
                    .OrderInfo,
                },
                {
                  element: <StockOrders.Products />,
                  path: AppRoutes.Shipments.Children.StockOrders.New.Children
                    .Products,
                },
              ],
            },
            {
              element: <StockOrders.Edit />,
              path: AppRoutes.Shipments.Children.StockOrders.Edit.Home,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      replace
                      to={
                        AppRoutes.Shipments.Children.StockOrders.Edit.Children
                          .OrderInfo
                      }
                    />
                  ),
                },
                {
                  element: <StockOrders.OrderInfo />,
                  path: AppRoutes.Shipments.Children.StockOrders.Edit.Children
                    .OrderInfo,
                },
                {
                  element: <StockOrders.Products />,
                  path: AppRoutes.Shipments.Children.StockOrders.Edit.Children
                    .Products,
                },
              ],
            },
            {
              element: <StockOrders.Details />,
              path: AppRoutes.Shipments.Children.StockOrders.Details,
            },
          ],
        },
      ],
    },
    {
      title: 'Users',
      features: [Feature.USERS],
      icon: <Icon.GroupFilled />,
      path: AppRoutes.Users.Home,
      /**
       * Added to hide tab for company owner.
       * If you remove users permission from company owner on the backend, it won't be possible to create a new employee.
       */
      hideForRoles: [Roles.COMPANY_OWNER],
      children: [
        {
          index: true,
          element: <Users.List />,
        },
        {
          element: <Users.Create />,
          path: AppRoutes.Users.New,
        },
        {
          element: <Users.Edit />,
          path: AppRoutes.Users.Edit,
        },
        {
          element: <Users.Details />,
          path: AppRoutes.Users.Details,
        },
      ],
    },
    {
      title: 'Settings',
      element: <Settings />,
      icon: <Icon.Settings />,
      path: AppRoutes.Settings.Home,
      features: [
        Feature.THEMES,
        Feature.PRODUCT_TYPES,
        Feature.PAYMENT_METHODS,
      ],
      children: [
        {
          index: true,
          element: (
            <Navigate replace to={AppRoutes.Settings.Children.ProductTypes} />
          ),
        },
        {
          element: <ProductTypes />,
          path: AppRoutes.Settings.Children.ProductTypes,
        },
        {
          element: <PaymentMethods />,
          path: AppRoutes.Settings.Children.PaymentMethods,
        },
        {
          element: <Themes />,
          path: AppRoutes.Settings.Children.Themes,
        },
        {
          element: <Carriers />,
          path: AppRoutes.Settings.Children.Carriers,
        },
        {
          element: <Shipping />,
          path: AppRoutes.Settings.Children.Shipping,
        },
        {
          element: <Integrations />,
          path: AppRoutes.Settings.Children.Integrations,
        },
        {
          element: <Receipts />,
          path: AppRoutes.Settings.Children.Receipts,
        },
        {
          element: <Devices />,
          path: AppRoutes.Settings.Children.Devices,
        },
        {
          element: <Permissions />,
          path: AppRoutes.Settings.Children.Permissions,
        },
        {
          element: <BusinessInfo />,
          path: AppRoutes.Settings.Children.BusinessInfo,
        },
        {
          element: <Notifications />,
          path: AppRoutes.Settings.Children.Notifications,
        },
      ],
    },
  ];
};
