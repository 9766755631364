import dayjs from 'dayjs';

import { URL } from 'api/constants';
import { MockHandler } from 'store/query';
import { DepositType, BackOfficeRoles, ChargebackStatus } from 'types';

import { Event } from '../event';
import { Employee } from '../employees';
import { Customer } from '../customers/types';

import {
  Deposit,
  GiftCard,
  Chargeback,
  TavlaSummary,
  TavlaPayment,
  RevenueCredit,
  RevenueDeposit,
  RevenueSummary,
  TavlaBackOffice,
  TavlaTransaction,
} from './types';

const employee = {
  id: 1,
  user: {
    name: 'John Doe',
    role: {
      name: BackOfficeRoles.SALES_REP,
    },
  },
} as Employee;

const customer = {
  id: 1,
  name: 'John Doe',
} as Customer;

const event = {
  id: 1,
  name: 'Some test event',
} as Event;

const mockDeposits: Deposit[] = [
  {
    id: 1,
    amount: 100,
    last4: 1234,
    type: DepositType.Deposit,
    dateInitiated: dayjs().toString(),
    date: dayjs().add(1, 'hour').toString(),
    fees: [
      {
        id: 1,
        amount: 50,
        date: dayjs().toString(),
      },
      {
        id: 2,
        amount: 25.5,
        date: dayjs().add(1, 'day').toString(),
      },
    ],
    transactions: [
      {
        id: 1,
        total: 100,
        card: 1234,
        amount: 100,
        brand: 'Visa',
        type: 'Type 1',
        date: dayjs().toString(),
      },
      {
        id: 2,
        fees: 50,
        total: 200,
        card: 5123,
        amount: 150,
        brand: 'Visa',
        type: 'Type 2',
        date: dayjs().toString(),
      },
    ],
  },
];

const mockChargebacks: Chargeback[] = [
  {
    id: 1,
    event,
    employee,
    customer,
    amount: 100,
    last4: 1234,
    date: dayjs().toString(),
    status: ChargebackStatus.Review,
    dueDate: dayjs().add(1, 'day').toString(),
    responseDate: dayjs().add(2, 'day').toString(),
  },
  {
    id: 2,
    employee,
    customer,
    amount: 200,
    last4: 1234,
    status: ChargebackStatus.ActionRequired,
    date: dayjs().subtract(1, 'hour').toString(),
    dueDate: dayjs().subtract(1, 'hour').toString(),
    responseDate: dayjs().subtract(1, 'hour').toString(),
    event: {
      id: 2,
      name: 'New event',
    } as Event,
  },
  {
    id: 3,
    employee,
    customer,
    amount: 300,
    last4: 1234,
    status: ChargebackStatus.ClosedWon,
    date: dayjs().subtract(1, 'day').toString(),
    dueDate: dayjs().subtract(1, 'day').toString(),
    responseDate: dayjs().subtract(1, 'day').toString(),
    event: {
      id: 3,
      name: 'Another event',
    } as Event,
  },
];

const mockGiftCards: GiftCard[] = [
  {
    id: 1,
    event,
    code: 1234,
    total: 100,
    eventId: 1,
    amount: 100,
    date: dayjs().toString(),
  },
  {
    id: 2,
    code: 1234,
    total: 200,
    eventId: 2,
    amount: 200,
    date: dayjs().subtract(1, 'hour').toString(),
    event: {
      id: 2,
      name: 'New event',
    } as Event,
  },
  {
    id: 3,
    code: 1234,
    total: 300,
    eventId: 3,
    amount: 300,
    date: dayjs().subtract(1, 'day').toString(),
    event: {
      id: 3,
      name: 'Another event',
    } as Event,
  },
];

const mockTavlaSummary: TavlaSummary[] = [
  {
    id: 1,
    customer,
    income: 100,
    balance: 250,
    expenses: 100,
    payments: 500,
    royalties: 1350,
    backOffice: 150,
  },
  {
    id: 2,
    income: 200,
    balance: 550,
    expenses: 400,
    payments: 200,
    royalties: 350,
    backOffice: 1050,
    customer: { ...customer, name: 'Nick Doe' },
  },
  {
    id: 3,
    income: 300,
    balance: 750,
    expenses: 600,
    payments: 300,
    royalties: 150,
    backOffice: 750,
    customer: { ...customer, name: 'Jane Doe' },
  },
];

const events = [
  event,
  { id: 2, name: 'New event' },
  { id: 3, name: 'Another event' },
  { id: 4, name: 'Event 4' },
  { id: 5, name: 'Event 5' },
  { id: 6, name: 'Event 6' },
  { id: 7, name: 'Event 7' },
  { id: 8, name: 'Event 8' },
  { id: 9, name: 'Event 9' },
  { id: 10, name: 'Event 10' },
  { id: 11, name: 'Event 11' },
];

const mockTavlaBackOffice: TavlaBackOffice[] = events.map((item, index) => ({
  id: item.id,
  eventId: item.id,
  event: item as Event,
  royalties: 150 + index * 50,
  backOfficeAmount: 100 + index * 50,
  date: dayjs()
    .subtract(index % 8, 'months')
    .toString(),
}));

const mockTavlaTransactions: TavlaTransaction[] = [
  {
    id: 1,
    event,
    eventId: 1,
    income: 125,
    expense: 100,
    date: dayjs().toString(),
    details:
      'President often mixes and matches political and legal strategies when he comes under investigation.',
  },
  {
    id: 2,
    eventId: 2,
    income: 150,
    expense: 200,
    date: dayjs().subtract(1, 'hour').toString(),
    event: {
      id: 2,
      name: 'New event',
    } as Event,
    details:
      'President often mixes and matches political and legal strategies when he comes under investigation.',
  },
  {
    id: 3,
    eventId: 3,
    income: 175,
    expense: 300,
    date: dayjs().subtract(1, 'day').toString(),
    event: {
      id: 3,
      name: 'Another event',
    } as Event,
    details:
      'President often mixes and matches political and legal strategies when he comes under investigation.',
  },
];

const mockTavlaPayments: TavlaPayment[] = [
  {
    id: 1,
    event,
    eventId: 1,
    amount: 125,
    paymentMethod: 'Cash',
    date: dayjs().toString(),
    details:
      'President often mixes and matches political and legal strategies when he comes under investigation.',
  },
  {
    id: 2,
    eventId: 2,
    amount: 125,
    paymentMethod: 'Cash',
    date: dayjs().subtract(1, 'hour').toString(),
    event: {
      id: 2,
      name: 'New event',
    } as Event,
    details:
      'President often mixes and matches political and legal strategies when he comes under investigation.',
  },
  {
    id: 3,
    eventId: 3,
    amount: 125,
    paymentMethod: 'Cash',
    date: dayjs().subtract(1, 'day').toString(),
    event: {
      id: 3,
      name: 'Another event',
    } as Event,
    details:
      'President often mixes and matches political and legal strategies when he comes under investigation.',
  },
];

const mockRevenueDeposit: RevenueDeposit[] = [
  {
    id: 1,
    fees: 50,
    total: 100,
    deposit: 100,
    chargeback: 1,
    date: dayjs().toString(),
    batchDate: dayjs().toString(),
  },
  {
    id: 2,
    fees: 150,
    total: 200,
    deposit: 200,
    chargeback: 0,
    date: dayjs().subtract(1, 'hour').toString(),
    batchDate: dayjs().subtract(1, 'hour').toString(),
  },
  {
    id: 3,
    fees: 25,
    total: 300,
    deposit: 300,
    chargeback: 0,
    date: dayjs().subtract(1, 'day').toString(),
    batchDate: dayjs().subtract(1, 'day').toString(),
  },
];

const mockRevenueSummary: RevenueSummary[] = [
  {
    id: 1,
    event,
    deposit: 100,
    revenue: 100,
  },
  {
    id: 2,
    deposit: 200,
    revenue: 200,
    event: {
      id: 2,
      name: 'New event',
    } as Event,
  },
  {
    id: 3,
    deposit: 300,
    revenue: 300,
    event: {
      id: 3,
      name: 'Other event',
    } as Event,
  },
];

const mockRevenueCredit: RevenueCredit[] = [
  {
    id: 1,
    total: 140,
    credit: 100,
    date: dayjs().toString(),
  },
  {
    id: 2,
    total: 240,
    credit: 200,
    date: dayjs().toString(),
  },
  {
    id: 3,
    total: 340,
    credit: 300,
    date: dayjs().toString(),
  },
];

export const expensesMockApiHandlers: MockHandler[] = [
  {
    method: 'get',
    responseData: mockDeposits,
    url: URL.GET_ACCOUNTING_DEPOSITS,
  },
  {
    method: 'get',
    responseData: mockChargebacks,
    url: URL.GET_ACCOUNTING_CHARGEBACKS,
  },
  {
    method: 'get',
    responseData: mockGiftCards,
    url: URL.GET_ACCOUNTING_GIFT_CARDS,
  },
  {
    method: 'get',
    responseData: mockTavlaSummary,
    url: URL.GET_ACCOUNTING_TAVLA_SUMMARY,
  },
  {
    method: 'get',
    responseData: mockTavlaBackOffice,
    url: URL.GET_ACCOUNTING_TAVLA_BACK_OFFICE,
  },
  {
    method: 'get',
    responseData: mockTavlaTransactions,
    url: URL.GET_ACCOUNTING_TAVLA_TRANSACTIONS,
  },
  {
    method: 'get',
    responseData: mockTavlaPayments,
    url: URL.GET_ACCOUNTING_TAVLA_PAYMENTS,
  },
  {
    method: 'get',
    responseData: mockRevenueDeposit,
    url: URL.GET_ACCOUNTING_REVENUE_DEPOSITS,
  },
  {
    method: 'get',
    responseData: mockRevenueSummary,
    url: URL.GET_ACCOUNTING_REVENUE_SUMMARY,
  },
  {
    method: 'get',
    responseData: mockRevenueCredit,
    url: URL.GET_ACCOUNTING_REVENUE_CREDIT,
  },
];
