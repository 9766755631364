import React, {
  useMemo,
  useState,
  createContext,
  PropsWithChildren,
} from 'react';

import { LinkProps } from 'components';
import { useAppSelector } from 'store';
import { RouteObject, getRouteObjects } from 'config/route-objects';
import { selectAuthUserRole, selectAuthUserFeatures } from 'store/slices/auth';

interface SidebarContextProps {
  isOpened: boolean;
  links: LinkProps[];
  setOpened: (isOpened: boolean) => void;
}

const getInitialState = (): SidebarContextProps => ({
  links: [],
  isOpened: false,
  setOpened: () => {},
});

export const SidebarContext =
  createContext<SidebarContextProps>(getInitialState());

export const SidebarContextProvider = ({ children }: PropsWithChildren) => {
  const [isOpened, setOpened] = useState(getInitialState().isOpened);
  const authUserFeatures = useAppSelector(selectAuthUserFeatures);
  const userRole = useAppSelector(selectAuthUserRole);

  const links = useMemo(() => {
    const mapLinks = (items: RouteObject[]): LinkProps[] => {
      return items
        .filter(({ path, title, features, hideForRoles, hideInSidebar }) => {
          if (
            !path ||
            !title ||
            hideInSidebar ||
            (userRole && hideForRoles?.includes(userRole))
          ) {
            return false;
          }

          return features
            ? features.some((feature) => authUserFeatures?.includes(feature))
            : true;
        })
        .map(({ icon, path, title, children: linkChildren }) => ({
          icon,
          path,
          title,
          items: linkChildren ? mapLinks(linkChildren) : undefined,
        })) as LinkProps[];
    };

    return mapLinks(getRouteObjects(authUserFeatures));
  }, [authUserFeatures]);

  const value = {
    links,
    isOpened,
    setOpened,
  };

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};
