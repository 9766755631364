import { useHeader } from 'context';
import { mapQuery } from 'utils/query';
import { Messages, AppRoutes } from 'config';
import { List, ListHeader, NewEntityButton } from 'components';
import { useQuery, useSearchQuery, handleMutation } from 'hooks';
import { Storage, useListQuery, useRemoveMutation } from 'store/api/storage';

import { Card } from './card';
import * as Styles from './styles';
import { getTitles } from './config';
import { StorageListProps } from './types';

export const StorageList = ({
  hideTabs,
  pageName,
  defaultType,
  hideCreateButton,
  hideDeleteButton,
}: StorageListProps) => {
  const [type, setType] = useQuery<Storage['type']>(
    'type',
    defaultType || 'storage'
  );
  const { debounceQuery, ...search } = useSearchQuery();
  const [remove, removeMutation] = useRemoveMutation();

  handleMutation({
    ...removeMutation,
    successMessage: `${pageName} ${Messages.DELETED_SUCCESSFULLY}`,
  });

  useHeader({ pageName });

  const { data = [], ...props } = useListQuery({ type, search: debounceQuery });

  return (
    <>
      {!hideTabs && (
        <Styles.Tabs
          value={type}
          onChange={(value) => setType(value as Storage['type'])}
          items={[
            { to: 'storage', label: 'Storage' },
            { to: 'event', label: 'Event' },
          ]}
        />
      )}

      <List
        {...mapQuery(props)}
        {...search}
        count={data.length}
        mainWrapperStyles={{ paddingBottom: '16px' }}
        header={<ListHeader titles={getTitles(hideDeleteButton)} />}
        searchBarRightSlot={
          !hideCreateButton ? (
            <NewEntityButton
              text="New storage"
              path={AppRoutes.Inventory.Storages.New}
            />
          ) : undefined
        }
      >
        {data.map((item) => (
          <Card
            data={item}
            key={item.id}
            action={remove}
            hideDeleteButton={hideDeleteButton}
          />
        ))}
      </List>
    </>
  );
};
