import { ReactNode } from 'react';

import { ListHeader } from 'components/list-header';
import { CustomerData } from 'store/api/customers/types';
import { ListCard, ListCardProps } from 'components/list-card';
import { ListProps, List as ListComponent } from 'components/list';

const titles = ['Customer name', 'Employee'];

export interface CustomersListProps extends ListProps {
  data: CustomerData[] | undefined;
  onCustomerSelect?: (customer: CustomerData) => void;
  renderCard?: (props: {
    data: CustomerData;
    cardProps: ListCardProps;
  }) => ReactNode;
}

export const List = ({
  data,
  renderCard,
  onCustomerSelect,
  ...props
}: CustomersListProps) => {
  return (
    <ListComponent
      {...props}
      count={data?.length}
      header={<ListHeader titles={titles} />}
    >
      {data?.map((customer) => {
        const cardProps: ListCardProps = {
          headers: titles,
          values: [customer.person.name, customer.employee.name],
          onClick: async () => {
            onCustomerSelect?.(customer);
          },
        };

        if (renderCard) {
          return renderCard({ cardProps, data: customer });
        }

        return <ListCard key={customer.id} {...cardProps} />;
      })}
    </ListComponent>
  );
};
