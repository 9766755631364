import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { mockAccountingApiQuery } from 'store/mockQuery';

import { AccountingSale, AccountingMethod } from './types';

const tagTypes = ['AccountingSales'];

export const accountingSalesApi = createApi({
  tagTypes,
  reducerPath: 'accountingSalesApi',
  baseQuery: mockAccountingApiQuery,
  endpoints: (build) => ({
    sales: build.query<AccountingSale, void>({
      providesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'get',
        url: URL.GET_ACCOUNTING_SALES,
      }),
    }),
  }),
});

export const { useSalesQuery } = accountingSalesApi;

export type { AccountingSale, AccountingMethod };
