import numeral from 'numeral';
import { generatePath } from 'react-router-dom';
import parsePhoneNumber from 'libphonenumber-js';
import { FormikValues, FormikConfig } from 'formik';
import { MouseEventHandler, ChangeEventHandler } from 'react';
import scrollIntoView, {
  StandardBehaviorOptions as ScrollOptions,
} from 'scroll-into-view-if-needed';

import { useAlert } from 'hooks';
import { URL } from 'api/constants';
import { Cart } from 'store/api/cart';
import { opsApiInstance } from 'api/instance';
import { EventCheckout } from 'store/api/event';
import { CartItem } from 'store/api/cart/types';

import { EmptyPlaceholder } from '../config';
import {
  Nullable,
  FileType,
  UploadFile,
  BaseEntity,
  PaymentMethod,
  PaymentMethodType,
} from '../types';
import {
  SelectValue,
  SelectOption,
  InputFieldProps,
  MultiSelectValue,
  SelectFieldProps,
  SingleSelectValue,
} from '../components';

import { replaceAll } from './string';

export const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const offset = (page: number, size: number): number => {
  return (page - 1) * size;
};

export const joinStrings = (
  values: Nullable<string>[],
  separator: string,
  placeholder: string | null = EmptyPlaceholder
): string => {
  const data = values.filter((value) => value && value?.length > 0) as string[];
  return data.length === 0 ? (placeholder ?? '') : data.join(separator);
};

export const getFullName = (
  firstName: Nullable<string>,
  lastName: Nullable<string>
) => {
  return joinStrings([firstName, lastName], ' ');
};

export const fullNameComponents = (fullName?: string) => {
  if (!fullName) {
    return {
      lastName: undefined,
      firstName: undefined,
    };
  }
  const components = fullName.split(' ');
  return {
    firstName: components[0],
    lastName: components.length > 1 && components[components.length - 1],
  };
};

export const textValue = (
  value?: number | string | null,
  template?: string | null,
  placeholder: string = EmptyPlaceholder
): string | number => {
  if (!value || String(value).length === 0) {
    return placeholder;
  }
  if (value && template) {
    return template.replace('_value_', String(value));
  }
  return value;
};

export const getByPath = (object?: any, path?: string): any | undefined => {
  if (!object || !path) {
    return undefined;
  }
  return path.split('.').reduce((r, k) => r[k], object);
};

export const stringToBoolean = (value: string): boolean => value === 'true';

export const getValuesByKeyFromTreeNodes = <T, K extends keyof T>(
  data: T[],
  key: K,
  childKey: K
): unknown[] => {
  return data.reduce((acc, item) => {
    const children = item[childKey] as unknown as T[];
    const childrenKeys = children
      ? getValuesByKeyFromTreeNodes(children, key, childKey)
      : [];

    return [...acc, item[key], ...childrenKeys];
  }, [] as unknown[]);
};

export const addIndexesToTreeNodes = <T, K extends keyof T>(
  data: T[],
  childKey: K
): T[] => {
  return data.map((item, index) => {
    const children = item[childKey] as unknown as T[];

    if (children) {
      return {
        ...item,
        $$index: index,
        [childKey]: addIndexesToTreeNodes(children, childKey),
      };
    }

    return { ...item, $$index: index };
  });
};

export const formatPhone = (
  phone?: string,
  placeholder: string = EmptyPlaceholder,
  national: boolean = true
) => {
  if (!phone) {
    return placeholder;
  }
  const phoneNumber = parsePhoneNumber(phone);
  if (!phoneNumber) {
    return phone;
  }
  return national
    ? phoneNumber.formatNational()
    : phoneNumber.formatInternational();
};

export const chunkArray = <T>(items: T[], split: number) =>
  items.reduce((chunks: T[][], item: T, index) => {
    const chunk = Math.floor(index / split);
    chunks[chunk] = ([] as T[]).concat(chunks[chunk] || [], item);
    return chunks;
  }, []);

export const splitPhones = (phones: string[]) => {
  const mobilePhones: string[] = [];
  const homePhones: string[] = [];
  phones.forEach((phone, index) => {
    if (index % 2 === 0) {
      mobilePhones.push(phone);
    } else {
      homePhones.push(phone);
    }
  });
  return { homePhones, mobilePhones };
};

export const sortSelectOptions = (options: SelectOption[]) => {
  return options.sort((a, b) => {
    const nameA = a.label.toLowerCase() ?? '';
    const nameB = b.label.toLowerCase() ?? '';
    if (nameA === nameB) {
      return 0;
    }
    return nameA > nameB ? 1 : -1;
  });
};

type PluralOptions = {
  onlyWord?: boolean;
  rightCount?: boolean;
  pluralSuffix?: string;
  singularSuffix?: string;
};

export const getPlural = (
  word: string,
  count: number,
  options?: PluralOptions
) => {
  const singularSuffix = options?.singularSuffix ?? '';
  const pluralSuffix = options?.pluralSuffix ?? 's';
  const rightCount = options?.rightCount ?? false;
  const resultWord = `${word}${count === 1 ? singularSuffix : pluralSuffix}`;
  if (options?.onlyWord) {
    return resultWord;
  }
  return rightCount ? `${count} ${resultWord}` : `${resultWord} - ${count}`;
};

export const hasSelection = () => {
  const selection = window.getSelection();
  return (
    selection && selection.toString().length > 0 && selection.type === 'Range'
  );
};

export const handleSelectionOnClick = <T = HTMLDivElement>(
  onClick?: MouseEventHandler<T>
) => {
  if (!onClick) {
    return undefined;
  }
  const handle: MouseEventHandler<T> = (e) => {
    if (hasSelection()) {
      return;
    }
    onClick(e);
  };
  return handle;
};

export const handleSelectionOnChange = <T = HTMLInputElement>(
  onChange?: ChangeEventHandler<T>
) => {
  if (!onChange) {
    return undefined;
  }
  const handle: ChangeEventHandler<T> = (e) => {
    if (hasSelection()) {
      return;
    }
    onChange(e);
  };
  return handle;
};

export const isFilterApplied = (value: unknown): boolean => {
  if (value instanceof Set) {
    return value.size > 0;
  } else if (Array.isArray(value) || typeof value === 'string') {
    return value.length > 0;
  } else if (typeof value === 'number') {
    return value !== 0;
  } else if (typeof value === 'object' && value) {
    return Object.values(value).some(Boolean);
  }

  return Boolean(value);
};

export const areSomeFiltersApplied = <T extends Record<string, any>>(
  filters: T
): boolean => Object.values(filters).some(isFilterApplied);

export const getRandomColor = <T extends object>(
  name: string,
  anyEnum: T
): T[keyof T] => {
  let sum = 0;
  const colors = Object.values(anyEnum);

  for (let i = 0; i < name.length; i++) {
    sum += name.charCodeAt(i);
  }

  const index = sum % colors.length;
  return colors[index] as unknown as T[keyof T];
};

export const getUserInitials = (name: string) => {
  return name
    .split(/\s+/)
    .filter((n) => n.length > 0)
    .map((n) => n[0].toUpperCase())
    .join('');
};

export const formatNumber = (value?: any, format: string = '0,0') => {
  return numeral(value).format(format);
};

export const formatPrice = (value?: any, format: string = '$0.00') => {
  return formatNumber(value, format);
};

export const isSingleSelectValue = (
  value: unknown
): value is SingleSelectValue =>
  typeof value === 'object' &&
  value !== null &&
  'value' in value &&
  'label' in value;

export const isMultiSelectValue = (
  value: unknown
): value is MultiSelectValue => {
  if (Array.isArray(value)) {
    if (value.length > 0) {
      return 'label' in value[0] && 'value' in value[0];
    }

    return true;
  }

  return false;
};

export const isSelectValue = (value: unknown): value is SelectValue =>
  isMultiSelectValue(value) || isSingleSelectValue(value);

export const getSelectValue = ({
  value,
  options,
  onlyValue,
  isCreatable,
}: {
  value: string | string[] | SelectValue;
} & Pick<
  SelectFieldProps,
  'options' | 'onlyValue' | 'isCreatable'
>): SelectValue => {
  if (!onlyValue && isSelectValue(value)) {
    return value;
  }
  const getOption = (search?: any): SingleSelectValue => {
    let option = options?.find(
      (item) => !Array.isArray(item) && (item as any).value === search
    );
    if (!option && isCreatable && search) {
      option = { label: search, value: search };
    }
    if (isSingleSelectValue(option)) {
      return option;
    }
  };
  if (Array.isArray(value)) {
    return value?.map((item) => getOption(item)) as MultiSelectValue;
  }
  return getOption(value) ?? null;
};

export const basicScrollOptions: ScrollOptions = {
  block: 'nearest',
  inline: 'nearest',
  behavior: 'smooth',
  scrollMode: 'if-needed',
};

export const scrollToId = (
  id: string,
  options: ScrollOptions = basicScrollOptions
) => {
  const element = document.getElementById(id);
  if (element) {
    scrollIntoView(element, options);
  }
};

export const scrollToClassName = (
  name: string,
  options: ScrollOptions = basicScrollOptions
) => {
  const elements = document.getElementsByClassName(name);
  const element = elements.length > 0 ? elements.item(0) : null;
  if (element) {
    scrollIntoView(element, options);
  }
};

export const scrollToErrorMessage = (
  options: ScrollOptions = basicScrollOptions
) => {
  const elements = document.getElementsByClassName('error-message');
  const element = elements.length > 0 ? elements.item(0) : null;
  if (element) {
    scrollIntoView(element.parentElement ?? element, options);
  }
};

export const arrayMove = (
  arr: Array<any>,
  oldIndex: number,
  newIndex: number
) => {
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const waitForElementToExist = (selector: string) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    });
  });
};

export const ordinalNumber = (value: number) => {
  let suffix = '';
  switch (value) {
    case 1:
      suffix = 'st';
      break;
    case 2:
      suffix = 'nd';
      break;
    case 3:
      suffix = 'rd';
      break;
    default:
      suffix = 'th';
      break;
  }
  return `${value}${suffix}`;
};

export const uploadMultimedia = async <T extends Record<string, any>>(
  file: File,
  url: string
): Promise<T> => {
  const formData = new FormData();
  formData.append('file', file);

  return opsApiInstance.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// TODO: remove it when backend format all phones in one format
export const transformPhone = (phone: string | undefined) => {
  if (!phone) {
    return phone;
  }
  if (phone.startsWith('1')) {
    return '+' + phone;
  }
  if (!phone.startsWith('+1')) {
    return '+1' + phone;
  }

  return phone;
};

export const fillObjectByPath = (
  values: Record<string, any>,
  value: any,
  path: string
) => {
  const names = path.split('.');
  var dir = { ...values };

  names.reduce<Record<string, any>>((nextEnt, key, idx) => {
    if (!nextEnt[key]) {
      nextEnt[key] = {};
    }
    if (idx === names.length - 1) {
      nextEnt[key] = value;
    }
    return nextEnt[key];
  }, dir);

  return dir;
};

export const getDeepCopy = <T>(data: T) => {
  if (!data) {
    return data;
  }
  return structuredClone
    ? structuredClone(data)
    : (JSON.parse(JSON.stringify(data)) as T);
};

export const mergeObjects = (
  enteringObj1: Record<string, any>,
  enteringObj2: Record<string, any>
) => {
  const obj1 = getDeepCopy(enteringObj1);
  const obj2 = getDeepCopy(enteringObj2);

  const isValid = (val: any) => {
    return typeof val === 'object' && !Array.isArray(val) && val !== null;
  };
  const result = { ...obj1 };

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (isValid(obj2[key])) {
        if (isValid(result[key])) {
          result[key] = mergeObjects(result[key], obj2[key]);
        } else {
          result[key] = { ...obj2[key] };
        }
      } else {
        result[key] = obj2[key];
      }
    }
  }

  return result;
};

export const generateTemplate = (count: number, value = 'minmax(50px, 1fr)') =>
  Array.from({ length: count })
    .map(() => value)
    .join(' ');

export const addPercentageLabel = (value: string | number | undefined) =>
  `${value}%`;

export const makeSelectOptionsFromEntities = <T extends BaseEntity>(
  data: T[] | undefined
): SelectOption[] =>
  data?.map(({ id, name }) => ({
    value: id,
    label: name,
  })) ?? [];

export const makeSelectOptionsFromOptionsArray = (
  options: readonly string[],
  labelsObject?: Record<string, string>
): { label: string; value: string | number }[] =>
  options.map((value) => ({
    value,
    label: labelsObject ? labelsObject[value] : value,
  }));

export const joinRoutes = (
  routes: string[],
  params?: Record<string, string | number>
) => {
  const route = replaceAll(joinStrings(routes, '/'), '//', '/');
  if (!params) {
    return route;
  }
  return generatePath(route, params);
};

export const extractFileNameAndType = (url?: string) => {
  if (!url) {
    return { name: '', type: '' };
  }

  const fullFileName = url.split('/').pop();
  if (!fullFileName) {
    return { name: '', type: '' };
  }

  const [fileNameWithType] = fullFileName
    .split('-')
    .slice(1)
    .join('-')
    .split('.');
  const type = fullFileName.split('.').pop();

  return {
    type: type || '',
    name: fileNameWithType,
  };
};

export const getUploadedFile = (file?: string) => {
  if (file) {
    const { name, type } = extractFileNameAndType(file);
    const isImage = type === 'png' || type === 'jpg' || type === 'jpeg';
    return {
      src: file,
      file: { name, type: isImage ? FileType.Image : FileType.File } as File,
    };
  }
};

export const removeDefaultPropagation = (
  e: React.MouseEvent<
    HTMLDivElement | HTMLLabelElement | HTMLButtonElement | HTMLAnchorElement,
    MouseEvent
  >,
  withoutPrevent?: boolean
) => {
  if (!withoutPrevent) {
    e.preventDefault();
  }
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
};

export const handleFormSubmitWithFiles =
  <Values extends FormikValues, Data extends any>({
    data,
    files,
    onSubmit,
    handleError,
    fieldsToRemove = [],
  }: {
    data: Data;
    fieldsToRemove?: (keyof Values)[];
    onSubmit: FormikConfig<Values>['onSubmit'];
    handleError: ReturnType<typeof useAlert>['handleError'];
    files: {
      dataFieldName?: string;
      requestFieldName: string;
      formFieldName: keyof Values;
    }[];
  }): FormikConfig<Values>['onSubmit'] =>
  async (values, formikHelpers) => {
    const upload = async (file?: UploadFile, uploadedUrl?: string) => {
      if (!file) {
        return;
      }

      if (file.src === uploadedUrl) {
        return uploadedUrl;
      }

      const fileResponse = await uploadMultimedia<{
        data: { url: string };
      }>(file.file, URL.UPLOAD_FILE);

      return fileResponse.data.url;
    };

    try {
      const uploadedFileUrls = Object.fromEntries(
        await Promise.all(
          files.map(
            async ({ dataFieldName, formFieldName, requestFieldName }) => {
              const uploadedUrl = dataFieldName
                ? getByPath(data, dataFieldName)
                : (data as any)?.[requestFieldName];

              const uploadedUrlFinal = await upload(
                values[formFieldName] as UploadFile,
                uploadedUrl
              );
              if (formFieldName !== requestFieldName) {
                fieldsToRemove.push(formFieldName);
              }
              return [requestFieldName, uploadedUrlFinal];
            }
          )
        )
      );

      const formData = { ...values, ...uploadedFileUrls };

      if (fieldsToRemove?.length) {
        fieldsToRemove.forEach((field) => {
          delete formData[field];
        });
      }

      onSubmit(formData, formikHelpers);
    } catch (e) {
      handleError(e);
    }
  };

export const normalizeCardNumber: InputFieldProps['normalize'] = (value) => {
  const limitedValue = value.slice(0, 16);

  const parts = [];

  for (let i = 0; i < limitedValue.length; i += 4) {
    parts.push(limitedValue.slice(i, i + 4));
  }

  return parts.length > 1 ? parts.join(' ') : limitedValue;
};

const calculateTotalAmount = (items?: CartItem[]): number =>
  Number(
    items
      ?.reduce((acc, item) => acc + item?.price * item?.quantity, 0)
      .toFixed(2)
  );

const calculateTax = (totalAmount: number, taxRate: number) =>
  Number(((totalAmount / 100) * taxRate).toFixed(2));

const calculateProcessingFees = (
  creditAmounts: number,
  processingFeesRate: number
): number => {
  return Number((creditAmounts * (processingFeesRate / 100)).toFixed(2));
};

const calculateCreditAmounts = (paymentMethods?: PaymentMethod[]) => {
  const creditInitialValue = { creditAmounts: 0, creditProccessingFees: 0 };

  return (
    paymentMethods?.reduce((acc, item) => {
      if (item.type === PaymentMethodType.Credit) {
        return {
          creditAmounts: acc.creditAmounts + item.amount,
          creditProccessingFees:
            acc.creditProccessingFees + (item.proccessingFee ?? 0),
        };
      }
      return acc;
    }, creditInitialValue) || creditInitialValue
  );
};

export const getCartAmounts = ({
  data,
  tip = 0,
  paymentMethods,
  shippingPrice = 0,
  companyProcessingFees = 0,
}: {
  tip?: number;
  shippingPrice?: number;
  data?: Cart | EventCheckout;
  companyProcessingFees?: number;
  paymentMethods?: PaymentMethod[];
}) => {
  const eventTax = data?.event?.tax ?? 0;

  const totalAmount = calculateTotalAmount(data?.items || []);
  const tax = calculateTax(totalAmount, eventTax);
  const netAmount = totalAmount + tax;

  const { creditAmounts, creditProccessingFees } =
    calculateCreditAmounts(paymentMethods);

  const processingFees = calculateProcessingFees(
    creditAmounts,
    companyProcessingFees
  );
  const saleTotal = Number(
    (totalAmount + tax + tip + shippingPrice + processingFees).toFixed(2)
  );

  return {
    tax,
    netAmount,
    saleTotal,
    totalAmount,
    creditAmounts,
    processingFees,
    creditProccessingFees,
  };
};

export const calculateCheckoutAmounts = (data?: EventCheckout) => {
  const eventTax = data?.tax ?? 0;
  const totalAmount = calculateTotalAmount(data?.items);
  const { creditAmounts } = calculateCreditAmounts(data?.paymentMethods);

  const processingFees = calculateProcessingFees(
    creditAmounts,
    data?.processingFees || 0
  );
  const tax = calculateTax(totalAmount, eventTax);
  const netAmount = totalAmount + tax;

  const saleTotal = Number(
    (
      netAmount +
      (data?.tip ?? 0) +
      (data?.shippingPrice ?? 0) +
      processingFees
    ).toFixed(2)
  );

  return {
    tax,
    netAmount,
    saleTotal,
    totalAmount,
    processingFees,
  };
};

export const downloadFile = ({
  data,
  type,
  fileName,
}: {
  data: any;
  fileName: string;
  type: BlobPropertyBag['type'];
}) => {
  const blob = new Blob([data], { type });

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const getPluralSalesRep = (data: EventCheckout) => ({
  value: data?.commissions?.map((value) => value.user?.name)?.join(', '),
  title: getPlural('Sales rep', data.commissions.length, {
    onlyWord: true,
  }),
});

export const getTotalAmount = <T extends Record<string, any>>(
  field: keyof T,
  data?: T[],
  isFormated: boolean = true
) => {
  const totalAmount = data?.reduce((acc, item) => acc + +item[field], 0);
  if (isFormated) {
    return formatPrice(totalAmount);
  }
  return totalAmount;
};
