import { Formik } from 'formik';

import { useHeader } from 'context';
import { ButtonColors } from 'types';
import { isMobile, useNavigate } from 'hooks';
import { makeSelectOptionsFromEntities } from 'utils/helpers';
import { useListQuery as useEventsQuery } from 'store/api/event';
import { useListQuery as useStoragesQuery } from 'store/api/storage';
import { useListQuery as useCompaniesQuery } from 'store/api/company';
import {
  StockTransferType,
  stockTransferTypeOptions,
} from 'store/api/stock-transfers/types';
import {
  Row,
  Col,
  Icon,
  Flex,
  Button,
  FormFooter,
  ButtonTypes,
  SelectField,
  CommonStyles,
  RadioFieldGroup,
  DatePickerField,
  SelectFieldProps,
} from 'components';

import { FormProps } from './types';
import { validationSchema } from './config';
import { StorageProductsSelectSection } from './storage-products-select-section';

export const Form = <T extends any | undefined>(props: FormProps<T>) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  const isEdit = 'id' in props.initialValues;

  const { data: events } = useEventsQuery({ status: 'accepted' });
  const { data: storages } = useStoragesQuery();
  const { data: companies } = useCompaniesQuery();

  useHeader({
    showBackButton: true,
    pageName: `${isEdit ? 'Edit' : 'New'} transfer`,
  });

  const eventSelectOptions =
    events?.map((event) => ({
      label: event.name,
      value: event.storage?.id,
    })) ?? [];
  const storageSelectOptions = makeSelectOptionsFromEntities(
    storages?.filter(({ type }) => type === 'storage')
  );
  const companySelectOptions = makeSelectOptionsFromEntities(companies);

  return (
    <Formik {...props} enableReinitialize validationSchema={validationSchema}>
      {({ values, isSubmitting, setFieldValue }) => {
        let sourceSelectFieldProps: Partial<SelectFieldProps> = {
          name: '',
        };
        let destinationSelectFieldProps: Partial<SelectFieldProps> = {
          name: '',
        };

        if (values.type === StockTransferType.EventToStorage) {
          sourceSelectFieldProps = {
            label: 'From: Event',
            options: eventSelectOptions,
          };

          destinationSelectFieldProps = {
            label: 'To: Storage',
            options: storageSelectOptions,
          };
        }

        if (values.type === StockTransferType.StorageToStorage) {
          sourceSelectFieldProps = {
            label: 'From: Storage',
            options: storageSelectOptions.filter(
              (option) => option.value !== values.destinationStorageId
            ),
          };

          destinationSelectFieldProps = {
            label: 'To: Storage',
            options: storageSelectOptions.filter(
              (option) => option.value !== values.sourceStorageId
            ),
          };
        }

        if (values.type === StockTransferType.StorageToEvent) {
          sourceSelectFieldProps = {
            label: 'From: Storage',
            options: storageSelectOptions,
          };

          destinationSelectFieldProps = {
            label: 'To: Event',
            options: eventSelectOptions,
          };
        }

        if (values.type === StockTransferType.ClientToClient) {
          sourceSelectFieldProps = {
            isDisabled: true,
            label: 'From: Client',
            name: 'sourceCompanyId',
            options: companySelectOptions.filter(
              (option) => option.value !== values.destinationCompanyId
            ),
          };

          destinationSelectFieldProps = {
            label: 'To: Client',
            name: 'destinationCompanyId',
            options: companySelectOptions.filter(
              (option) => option.value !== values.sourceCompanyId
            ),
          };
        }

        if (values.type === StockTransferType.EventToEvent) {
          sourceSelectFieldProps = {
            label: 'From: Event',
            options: eventSelectOptions.filter(
              (option) => option.value !== values.destinationStorageId
            ),
          };

          destinationSelectFieldProps = {
            label: 'To: Event',
            options: eventSelectOptions.filter(
              (option) => option.value !== values.sourceStorageId
            ),
          };
        }

        return (
          <CommonStyles.Form>
            <CommonStyles.FormInner>
              <Row>
                <Col>
                  <RadioFieldGroup
                    name="type"
                    items={stockTransferTypeOptions}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Flex gap={16}>
                    <SelectField
                      onlyValue
                      isClearable
                      name="sourceStorageId"
                      onChange={() => setFieldValue('items', [])}
                      {...sourceSelectFieldProps}
                    />
                    <Icon.Change style={{ marginTop: '32px' }} />
                    <SelectField
                      onlyValue
                      isClearable
                      name="destinationStorageId"
                      {...destinationSelectFieldProps}
                    />
                  </Flex>
                </Col>
              </Row>

              {values.type === StockTransferType.ClientToClient &&
                values.sourceCompanyId && (
                  <Row>
                    <Col>
                      <RadioFieldGroup
                        name="companyTransferType"
                        items={[
                          {
                            label: 'Storage',
                            value: 'storage',
                          },
                          {
                            label: 'Event',
                            value: 'event',
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                )}

              <Row>
                {values.type === StockTransferType.ClientToClient &&
                  values.sourceCompanyId && (
                    <Col col={3}>
                      <SelectField
                        onlyValue
                        name="sourceStorageId"
                        label={`From: ${values.companyTransferType === 'storage' ? 'Storage' : 'Event'}`}
                        options={
                          values.companyTransferType === 'storage'
                            ? storageSelectOptions
                            : eventSelectOptions
                        }
                      />
                    </Col>
                  )}

                <Col col={3}>
                  <DatePickerField name="date" label="Date" />
                </Col>
              </Row>

              {values.sourceStorageId && (
                <StorageProductsSelectSection
                  storageId={values.sourceStorageId}
                />
              )}
            </CommonStyles.FormInner>

            <FormFooter
              right={
                <CommonStyles.FormButtons>
                  <Button
                    text="Cancel"
                    fullWidth={mobile}
                    onClick={() => navigate(-1)}
                    colorType={ButtonColors.LightBlue}
                  />
                  <Button
                    fullWidth={mobile}
                    disabled={isSubmitting}
                    type={ButtonTypes.Submit}
                    text={isEdit ? 'Edit' : 'Create'}
                  />
                </CommonStyles.FormButtons>
              }
            />
          </CommonStyles.Form>
        );
      }}
    </Formik>
  );
};
