import dayjs from 'dayjs';

import { Popup } from './popup';

export const RelativeDate = ({ date }: { date: dayjs.ConfigType }) => {
  return (
    <Popup on="hover" trigger={<div>{dayjs(date).fromNow()}</div>}>
      <div style={{ padding: '4px' }}>{dayjs(date).format('LLLL')}</div>
    </Popup>
  );
};
