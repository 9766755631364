import styled from 'styled-components';

import { Badge } from 'components/badge';

export const BadgeContainer = styled.div`
  margin: 0 16px 16px;
`;

export const StyledBadge = styled(Badge)`
  width: 100%;
  justify-content: flex-start;
`;
