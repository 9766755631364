import dayjs from 'dayjs';
import { Fragment, useEffect } from 'react';
import { Formik, FieldArray, useFormikContext } from 'formik';

import { useHeader } from 'context';
import { ButtonColors } from 'types';
import { useAppSelector } from 'store';
import { useListQuery } from 'store/api/users';
import { EventFormValues } from 'store/api/event';
import { selectAuthUserType } from 'store/slices/auth';
import { isMobile, useAlert, useNavigate } from 'hooks';
import { useListQuery as useThemesQuery } from 'store/api/themes';
import { useListQuery as useProductsQuery } from 'store/api/product';
import { useListQuery as usePaymentMethodsQuery } from 'store/api/payment-methods';
import {
  handleFormSubmitWithFiles,
  makeSelectOptionsFromEntities,
} from 'utils/helpers';
import {
  Row,
  Col,
  Flex,
  Icon,
  Button,
  AddButton,
  FormFooter,
  InputField,
  RatingField,
  ButtonTypes,
  SelectField,
  SwitchField,
  DeleteButton,
  EllipsisText,
  CommonStyles,
  CheckboxField,
  SectionHeader,
  DatePickerField,
  TimePickerField,
  StatesSelectField,
  FileDropzoneField,
  FormPhotoFieldRow,
  EventTeamAssignSelects,
  AddressAutoCompleteInputField,
} from 'components';

import * as Styles from './styles';
import { FormProps } from './types';
import {
  validationSchema,
  getInitialValues,
  eventTypeOptions,
  paymentTypeOptions,
  transactionOptions,
  eventLocationTypeOptions,
} from './config';

const generateDates = (startDate: Date, endDate: Date) => {
  const dates = [];
  let currentDate = dayjs(startDate);

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
    dates.push(currentDate.toDate());
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
};

const ScheduleFields = () => {
  const { values, setFieldValue } = useFormikContext<EventFormValues>();

  const dates =
    values.startDate && values.endDate
      ? generateDates(values.startDate, values.endDate)
      : [];

  useEffect(() => {
    const setScheduleInitialFields = async () => {
      await setFieldValue(
        'schedule',
        dates.map((date) => {
          return {
            date,
            dayOff: false,
            time: {
              to: undefined,
              from: undefined,
            },
          };
        })
      );
    };

    if (dates.length && !values.schedule.length) {
      setScheduleInitialFields();
    }
  }, [dates.length, values.schedule]);

  const firstDayWithFilledTime = values.schedule.find(
    ({ time }) => time?.from !== undefined && time?.to !== undefined
  );

  const someTimeAreEmpty = values.schedule.some(
    ({ time }) => time?.from === undefined && time?.to === undefined
  );

  useEffect(() => {
    const fillSchedule = async () => {
      const newSchedule = values.schedule.map((item) => {
        if (item.time?.to === undefined && item.time?.from === undefined) {
          return {
            ...item,
            time: {
              to: firstDayWithFilledTime?.time?.to,
              from: firstDayWithFilledTime?.time?.from,
            },
          };
        }

        return item;
      });

      await setFieldValue('schedule', newSchedule);
    };

    if (firstDayWithFilledTime && someTimeAreEmpty) {
      fillSchedule();
    }
  }, [firstDayWithFilledTime, someTimeAreEmpty]);

  return dates.length ? (
    <Flex column gap={16} style={{ alignItems: 'flex-start' }}>
      {dates.map((date, index) => {
        return (
          <Flex gap={24} key={index}>
            <label>{dayjs(date).format('MMMM D, YYYY')}</label>

            <TimePickerField
              placeholder="From"
              name={`schedule.${index}.time.from`}
              isDisabled={Boolean(values.schedule[index]?.dayOff)}
            />

            <TimePickerField
              placeholder="To"
              name={`schedule.${index}.time.to`}
              minValue={values.schedule[index]?.time?.from}
              isDisabled={Boolean(values.schedule[index]?.dayOff)}
            />

            <SwitchField label="Day off" name={`schedule.${index}.dayOff`} />
          </Flex>
        );
      })}
    </Flex>
  ) : (
    <p>Select the start and end dates to choose a schedule</p>
  );
};

export const Form = ({ data, onSubmit }: FormProps) => {
  const mobile = isMobile();
  const navigate = useNavigate();
  const authUserType = useAppSelector(selectAuthUserType);
  const { handleError } = useAlert();

  const { data: users } = useListQuery();
  const { data: themes } = useThemesQuery();
  const { data: products } = useProductsQuery();
  const { data: paymentMethods } = usePaymentMethodsQuery({ status: 'active' });

  const isEdit = Boolean(data);

  useHeader({
    showBackButton: true,
    pageName: `${isEdit ? 'Edit' : 'New'} event`,
  });

  const userSelectOptions = makeSelectOptionsFromEntities(users);
  const themeSelectOptions = makeSelectOptionsFromEntities(themes);
  const productSelectOptions = makeSelectOptionsFromEntities(products);
  const paymentMethodOptions = makeSelectOptionsFromEntities(paymentMethods);

  return (
    <Formik<EventFormValues>
      validationSchema={validationSchema}
      initialValues={getInitialValues(data)}
      onSubmit={handleFormSubmitWithFiles({
        data,
        onSubmit,
        handleError,
        files: [
          {
            formFieldName: 'floorPlan',
            requestFieldName: 'floorPlanFileUrl',
          },
          {
            formFieldName: 'photo',
            requestFieldName: 'photoUrl',
          },
        ],
      })}
    >
      {({ values, isSubmitting, setFieldValue }) => {
        const setZipcodeAndTax = (zip: string, taxRate: string) => {
          setFieldValue('zip', zip);
          setFieldValue('tax', +taxRate);
        };

        return (
          <CommonStyles.Form>
            <CommonStyles.FormInner>
              <FormPhotoFieldRow />

              <SectionHeader withBorder={false} title="Event information" />

              <Row>
                <Col col={3}>
                  <InputField name="name" label="Name" placeholder="Name" />
                </Col>

                <Col col={3}>
                  <DatePickerField
                    name="startDate"
                    label="Start date"
                    minDate={new Date()}
                    maxDate={values.endDate}
                    placeholderText="Start date"
                  />
                </Col>

                <Col col={3}>
                  <DatePickerField
                    name="endDate"
                    label="End date"
                    placeholderText="End date"
                    minDate={values.startDate}
                  />
                </Col>
              </Row>

              <Row>
                <Col col={3}>
                  <InputField
                    name="sellingDays"
                    label="Selling days"
                    placeholder="Selling days"
                  />
                </Col>

                <Col col={3}>
                  <InputField
                    number
                    decimal
                    name="tax"
                    label="Tax, %"
                    placeholder="Tax, %"
                  />
                </Col>
              </Row>

              <Row>
                <Col col={3}>
                  <AddressAutoCompleteInputField
                    zipFieldName="zip"
                    onTaxSelected={setZipcodeAndTax}
                  />
                </Col>

                <Col col={3}>
                  <StatesSelectField isDisabled name="state" />
                </Col>

                <Col col={3}>
                  <InputField
                    disabled
                    name="city"
                    label="City"
                    placeholder="City"
                  />
                </Col>

                <Col col={3}>
                  <InputField
                    number
                    disabled
                    name="zip"
                    label="ZIP"
                    placeholder="ZIP"
                  />
                </Col>
              </Row>

              <SectionHeader title="Schedule" withBorder={false} />

              <ScheduleFields />

              <SectionHeader withBorder={false} title="Contact information" />
              <Row>
                <Col col={3}>
                  <InputField
                    name="contactName"
                    label="Contact Name"
                    placeholder="Contact Name"
                  />
                </Col>

                <Col col={3}>
                  <InputField
                    type="tel"
                    label="Phone"
                    name="contactPhone"
                    placeholder="Phone"
                  />
                </Col>

                <Col col={3}>
                  <InputField
                    label="Email"
                    name="contactEmail"
                    placeholder="Email"
                  />
                </Col>
              </Row>

              <SectionHeader
                withBorder={false}
                title="Event Theme & Products"
              />
              <Row>
                <Col col={3}>
                  <SelectField
                    onlyValue
                    label="Theme"
                    name="themeId"
                    placeholder="Theme"
                    options={themeSelectOptions}
                  />
                </Col>

                <Col col={3}>
                  <InputField name="link" label="Link" placeholder="Link" />
                </Col>

                <Col col={3}>
                  <SelectField
                    onlyValue
                    label="Product"
                    name="productId"
                    placeholder="Select product"
                    options={productSelectOptions}
                  />
                </Col>
              </Row>

              <SectionHeader withBorder={false} title="Attendance" />

              <Row>
                <Col col={6}>
                  <InputField
                    name="attendance"
                    label="Attendance"
                    placeholder="Add attendance"
                  />
                </Col>

                <Col col={6}>
                  <InputField
                    name="vendors"
                    label="Vendors"
                    placeholder="Add vendors"
                  />
                </Col>
              </Row>

              <Row>
                <Col col={6}>
                  <RatingField label="Event Rating" />
                </Col>

                <Col col={6}>
                  <InputField
                    name="ratingMessage"
                    label="Event Rating Message"
                    placeholder="Add event rating message"
                  />
                </Col>
              </Row>

              <SectionHeader title="Locations" withBorder={false} />

              <FieldArray
                name="locations"
                render={({ push, remove }) => (
                  <>
                    {values.locations?.map((location, index) => (
                      <Fragment key={index}>
                        <Row>
                          <Col col={3}>
                            <InputField
                              label="Name"
                              placeholder="Name"
                              name={`locations.${index}.name`}
                            />
                          </Col>
                          <Col col={3}>
                            <InputField
                              label="#"
                              placeholder="#"
                              name={`locations.${index}.number`}
                            />
                          </Col>
                          <Col col={3}>
                            <InputField
                              number
                              decimal
                              label="$"
                              placeholder="$"
                              name={`locations.${index}.income`}
                            />
                          </Col>
                          <Col col={2}>
                            <Flex gap={8}>
                              <InputField
                                number
                                decimal
                                label="Length, ft"
                                placeholder="Length, ft"
                                name={`locations.${index}.length`}
                              />
                              <InputField
                                number
                                decimal
                                label="Width, ft"
                                placeholder="Width, ft"
                                name={`locations.${index}.width`}
                              />
                            </Flex>
                          </Col>
                        </Row>

                        {(location.id || index > 0) && (
                          <Row>
                            <Col>
                              <DeleteButton
                                text="Remove location"
                                onClick={() => remove(index)}
                              />
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    ))}

                    <Row>
                      <Col>
                        <AddButton
                          text="Add location"
                          onClick={() =>
                            push({
                              name: '',
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </>
                )}
              />

              <Row>
                <Col col={4}>
                  <SelectField
                    onlyValue
                    name="locationType"
                    label="Location Type"
                    placeholder="Select location type"
                    options={eventLocationTypeOptions}
                  />
                </Col>
                <Col col={4}>
                  <SelectField
                    onlyValue
                    name="eventType"
                    label="Indoor/Outdoor"
                    options={eventTypeOptions}
                    placeholder="Select Indoor/Outdoor"
                  />
                </Col>
              </Row>

              <Row>
                <Col col={3}>
                  <Flex gap={4} minWidth={0} alignItems="center">
                    <FileDropzoneField name="floorPlan" label="Floor Plan">
                      <Flex gap={4} minWidth={0} alignItems="center">
                        <Button text="Choose file" />

                        {values.floorPlan && (
                          <EllipsisText>
                            {values.floorPlan.file.name}
                          </EllipsisText>
                        )}
                      </Flex>
                    </FileDropzoneField>

                    {values.floorPlan && (
                      <Styles.DeleteFileButton
                        onClick={() => setFieldValue('floorPlan', null)}
                      />
                    )}
                  </Flex>
                </Col>

                <Col col={3}>
                  <InputField
                    name="floorPlanLink"
                    label="Floor Plan Link"
                    leftIcon={<Icon.Link />}
                  />
                </Col>
                <Col col={3}>
                  <InputField
                    number
                    decimal
                    name="finalPrice"
                    label="Final Price"
                    leftIcon={<Icon.CoinDollar />}
                  />
                </Col>
              </Row>

              {authUserType === 'ops' && (
                <>
                  <Row>
                    <Col>
                      <InputField
                        type="textarea"
                        name="bookingTeamNotes"
                        label="Booking Team Notes"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <InputField
                        type="textarea"
                        name="notesForClient"
                        label="Notes For Client"
                      />
                    </Col>
                  </Row>
                </>
              )}

              <SectionHeader title="Payments" withBorder={false} />
              <FieldArray
                name="payments"
                render={({ push, remove }) => (
                  <>
                    {values.payments?.map((payment, index) => (
                      <Fragment key={index}>
                        <Row>
                          <Col col={2}>
                            <SelectField
                              onlyValue
                              menuPlacement="top"
                              label="Payment Type"
                              placeholder="Payment Type"
                              options={paymentTypeOptions}
                              name={`payments.${index}.type`}
                            />
                          </Col>
                          <Col col={2}>
                            <SelectField
                              onlyValue
                              menuPlacement="top"
                              label="Transaction"
                              placeholder="Transaction"
                              options={transactionOptions}
                              name={`payments.${index}.transaction`}
                            />
                          </Col>
                          <Col col={2}>
                            <InputField
                              label="Notes"
                              placeholder="Notes"
                              name={`payments.${index}.notes`}
                            />
                          </Col>
                          <Col col={2}>
                            <InputField
                              number
                              label="Amount"
                              placeholder="Amount"
                              leftIcon={<Icon.CoinDollar />}
                              name={`payments.${index}.amount`}
                            />
                          </Col>
                          <Col col={2}>
                            <DatePickerField
                              label="Date"
                              placeholderText="Date"
                              name={`payments.${index}.date`}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col col={2}>
                            <SelectField
                              onlyValue
                              menuPlacement="top"
                              label="Paid/Scheduled By"
                              options={userSelectOptions}
                              placeholder="Paid/Scheduled By"
                              name={`payments.${index}.paidById`}
                            />
                          </Col>
                          <Col col={3}>
                            <Flex gap={16} alignItems="center">
                              <SelectField
                                onlyValue
                                menuPlacement="top"
                                label="Payment Method"
                                options={paymentMethodOptions}
                                name={`payments.${index}.paymentMethodId`}
                              />
                              <CheckboxField
                                label="Cashed"
                                style={{ marginTop: 16 }}
                                name={`payments.${index}.cashed`}
                              />
                            </Flex>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <DeleteButton
                              text="Remove payment"
                              onClick={() => remove(index)}
                            />
                          </Col>
                        </Row>
                      </Fragment>
                    ))}

                    <AddButton
                      text="Add payment"
                      onClick={() => push({ type: '', cashed: false })}
                    />
                  </>
                )}
              />

              {authUserType === 'client' && (
                <>
                  <SectionHeader title="Team" withBorder={false} />
                  <EventTeamAssignSelects
                    menuPlacement="top"
                    colProps={{ col: 3 }}
                  />

                  <Row>
                    <Col col={6}>
                      <InputField
                        name="notes"
                        label="Notes"
                        placeholder="Notes"
                        type="textarea-autosize"
                      />
                    </Col>
                  </Row>
                </>
              )}
            </CommonStyles.FormInner>

            <FormFooter
              right={
                <CommonStyles.FormButtons>
                  <Button
                    text="Cancel"
                    fullWidth={mobile}
                    onClick={() => navigate(-1)}
                    colorType={ButtonColors.LightBlue}
                  />
                  <Button
                    fullWidth={mobile}
                    disabled={isSubmitting}
                    type={ButtonTypes.Submit}
                    text={isEdit ? 'Edit' : 'Create'}
                  />
                </CommonStyles.FormButtons>
              }
            />
          </CommonStyles.Form>
        );
      }}
    </Formik>
  );
};
