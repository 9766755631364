import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { cartApi } from './api/cart';
import { dealsApi } from './api/deal';
import { salesApi } from './api/sales';
import { usersApi } from './api/users';
import { eventApi } from './api/event';
import { payoutApi } from './api/payout';
import { themesApi } from './api/themes';
import { productApi } from './api/product';
import { companyApi } from './api/company';
import { storagesApi } from './api/storage';
import { authReducer } from './slices/auth';
import { expensesApi } from './api/expenses';
import { supplierApi } from './api/supplier';
import { settingsApi } from './api/settings';
import { carriersApi } from './api/carriers';
import { categoryApi } from './api/category';
import { analyticsApi } from './api/analytics';
import { customersApi } from './api/customers';
import { inventoryApi } from './api/inventory';
import { employeesApi } from './api/employees';
import { loaderReducer } from './slices/loader';
import { accountingApi } from './api/accounting';
import { attributesApi } from './api/attributes';
import { eventOrdersApi } from './api/event-orders';
import { stockOrdersApi } from './api/stock-orders';
import { appointmentsApi } from './api/appointments';
import { productTypesApi } from './api/product-types';
import { priceApprovalApi } from './api/price-approval';
import { stockTransfersApi } from './api/stock-transfers';
import { paymentMethodsApi } from './api/payment-methods';
import { accountingSalesApi } from './api/accounting-sales';

const apis = [
  cartApi,
  eventApi,
  usersApi,
  salesApi,
  dealsApi,
  payoutApi,
  themesApi,
  productApi,
  companyApi,
  expensesApi,
  carriersApi,
  supplierApi,
  categoryApi,
  settingsApi,
  storagesApi,
  customersApi,
  analyticsApi,
  inventoryApi,
  employeesApi,
  attributesApi,
  accountingApi,
  stockOrdersApi,
  eventOrdersApi,
  productTypesApi,
  appointmentsApi,
  priceApprovalApi,
  stockTransfersApi,
  paymentMethodsApi,
  accountingSalesApi,
];

const appReducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  ...Object.fromEntries(apis.map((api) => [api.reducerPath, api.reducer])),
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/clearAuth') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  /*TODO*/
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apis.map((api) => api.middleware)),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
