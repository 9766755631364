import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { SearchRequest } from 'types';
import { addParamsToUrl } from 'utils/query';
import {
  cacheByIdArg,
  providesList,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import {
  Employee,
  EmployeeFormValues,
  CreateEmployeeFormValues,
} from './types';

const tagType = 'Employees';

export const employeesApi = createApi({
  baseQuery: apiQuery,
  tagTypes: [tagType],
  reducerPath: 'employeesApi',
  endpoints: (build) => ({
    getInfoAboutMe: build.query<Employee, void>({
      providesTags: [tagType],
      query: () => ({
        method: 'get',
        url: URL.EMPLOYEE_ME,
      }),
    }),
    get: build.query<Employee, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.EMPLOYEE.replace(':id', id),
      }),
    }),
    remove: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.EMPLOYEE.replace(':id', id),
      }),
    }),
    list: build.query<Employee[], SearchRequest | void>({
      providesTags: providesList(tagType),
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.EMPLOYEES, { search: params?.search }),
      }),
    }),
    edit: build.mutation<void, EmployeeFormValues>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.EMPLOYEE.replace(':id', String(id)),
      }),
    }),
    create: build.mutation<void, CreateEmployeeFormValues>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => {
        delete data.repeatPassword;

        return {
          data,
          method: 'post',
          url: URL.EMPLOYEES,
        };
      },
    }),
  }),
});

export const {
  useGetQuery,
  useListQuery,
  useEditMutation,
  useCreateMutation,
  useRemoveMutation,
  useGetInfoAboutMeQuery,
} = employeesApi;

export type { Employee, EmployeeFormValues, CreateEmployeeFormValues };
