import { useParams } from 'react-router-dom';

import { useNavigate } from 'hooks';
import { useHeader } from 'context';
import { mapQuery } from 'utils/query';
import { formatDate } from 'utils/date';
import { formatPrice } from 'utils/helpers';
import { Messages, AppRoutes } from 'config';
import {
  useDetailsQuery,
  useRemoveMutation,
  StockTransferStatus,
} from 'store/api/stock-transfers';
import {
  List,
  Avatar,
  ListCard,
  ListHeader,
  GeneralInfo,
  DeleteDialog,
  Details as DetailsComponent,
} from 'components';

const Details = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, ...query } = useDetailsQuery(id!, { skip: !id });

  const [remove, mutation] = useRemoveMutation();

  useHeader({
    showBackButton: true,
    pageName: 'Stock transfer',
  });

  if (!data) {
    return null;
  }

  return (
    <DetailsComponent {...mapQuery(query)}>
      {data && (
        <GeneralInfo
          withoutPhoto
          withPlaceholders
          stickyWithHeader={false}
          name={`Stock transfer ${data.id}`}
          onEdit={
            data.status === StockTransferStatus.Completed
              ? undefined
              : () => navigate('edit')
          }
          rightSlot={
            data.status === StockTransferStatus.Completed ? undefined : (
              <DeleteDialog
                id={data.id}
                action={remove}
                mutation={mutation}
                name="stock transfer"
                navigateUrl={AppRoutes.Inventory.Transfers.Home}
                successMessage={`Stock transfer ${Messages.DELETED_SUCCESSFULLY}`}
              />
            )
          }
          sections={[
            {
              title: 'General info',
              rows: [
                [
                  { col: 3, title: 'Date', value: formatDate(data.date) },
                  { col: 3, title: 'Status', value: data.status },
                  {
                    col: 3,
                    title: 'Wholesale cost',
                    value: formatPrice(data.wholesaleCost),
                  },
                  {
                    col: 3,
                    title: 'Created By',
                    value: data.createdBy.name,
                  },
                ],
              ],
            },
            {
              title: 'Source storage',
              rows: [
                [
                  {
                    col: 2,
                    title: 'Name',
                    value: data.sourceStorage?.name,
                  },
                  {
                    col: 2,
                    title: 'Address',
                    value: data.sourceStorage?.address,
                  },
                  {
                    col: 2,
                    title: 'Phone',
                    value: data.sourceStorage?.phone,
                  },
                  {
                    col: 2,
                    title: 'Contact name',
                    value: data.sourceStorage?.contactName,
                  },
                  {
                    col: 2,
                    title: 'Emergency Phone',
                    value: data.sourceStorage?.emergencyPhone,
                  },
                ],
              ],
            },
            ...(data.destinationCompany
              ? [
                  {
                    title: 'Destination company',
                    rows: [
                      [
                        {
                          col: 2,
                          title: 'Name',
                          value: data.destinationCompany.name,
                        },
                      ],
                    ],
                  },
                ]
              : []),
            ...(data.destinationStorage
              ? [
                  {
                    title: 'Destination storage',
                    rows: [
                      [
                        {
                          col: 2,
                          title: 'Name',
                          value: data.destinationStorage.name,
                        },
                        {
                          col: 2,
                          title: 'Address',
                          value: data.destinationStorage.address,
                        },
                        {
                          col: 2,
                          title: 'Phone',
                          value: data.destinationStorage.phone,
                        },
                        {
                          col: 2,
                          title: 'Contact name',
                          value: data.sourceStorage?.contactName,
                        },
                        {
                          col: 2,
                          title: 'Emergency Phone',
                          value: data.sourceStorage?.emergencyPhone,
                        },
                      ],
                    ],
                  },
                ]
              : []),
            {
              title: 'Products',
              children: (
                <List
                  count={data.items.length}
                  style={{ marginBottom: '32px' }}
                  header={
                    <ListHeader
                      titles={['', 'Name', 'Count', 'Price', 'Total price']}
                    />
                  }
                >
                  {data.items.map((item) => (
                    <ListCard
                      key={item.productId}
                      values={[
                        <Avatar url={item.product.photoUrl} />,
                        item.product.name,
                        item.count,
                        item.price,
                        item.count * item.price,
                      ]}
                    />
                  ))}

                  <ListCard
                    withPlaceholders={false}
                    values={[
                      <h3>Total: </h3>,
                      '',
                      <h3>{formatPrice(data.wholesaleCost)}</h3>,
                    ]}
                  />
                </List>
              ),
            },
          ]}
        />
      )}
    </DetailsComponent>
  );
};

export default Details;
