import dayjs from 'dayjs';
import { generatePath } from 'react-router-dom';

import { useNavigate } from 'hooks';
import { useAppSelector } from 'store';
import { formatDate } from 'utils/date';
import { states, AppRoutes } from 'config';
import { Feature, ButtonColors } from 'types';
import { removeDefaultPropagation } from 'utils/helpers';
import { AcceptEventDialog } from 'pages/event/list/accept-event-dialog';
import {
  selectAuthUser,
  isAuthCompanyUser,
  selectAuthUserType,
  selectAuthUserFeatures,
} from 'store/slices/auth';
import {
  Flex,
  Icon,
  Popup,
  Button,
  Rating,
  Avatar,
  ListCard,
  EllipsisText,
  RelativeDate,
  ButtonWithRef,
} from 'components';

import * as Styles from './styles';
import { CardProps } from './types';
import { AssignTeamDialog } from './assign-team-dialog';
import {
  eventStatusText,
  titlesByUserType,
  templateByUserType,
} from './config';

const EventActionButton = ({ data, onEventOffer }: CardProps) => {
  const authUserFeatures = useAppSelector(selectAuthUserFeatures);
  const isCompanyUser = useAppSelector(isAuthCompanyUser);

  if (isCompanyUser && data.status === 'offered') {
    return <AcceptEventDialog event={data} />;
  }

  if (!data.companyId && authUserFeatures?.includes(Feature.EVENTS)) {
    return (
      <Button
        text="Offer event"
        onClick={(e) => {
          e.stopPropagation();
          onEventOffer(data.id);
        }}
        disabled={Boolean(
          data.companyId || !authUserFeatures?.includes(Feature.EVENTS)
        )}
      />
    );
  }

  return <div>{data.status ? eventStatusText[data.status] : 'No info'}</div>;
};

export const Card = ({ data, onEventOffer }: CardProps) => {
  const navigate = useNavigate();
  const authUser = useAppSelector(selectAuthUser);
  const isCompanyUser = useAppSelector(isAuthCompanyUser);
  const authUserType = useAppSelector(selectAuthUserType);

  const values = [
    <Flex gap={16} alignItems="center">
      <Avatar size={56} url={data.photoUrl} />
      <EllipsisText className="semibold">{data.name}</EllipsisText>
    </Flex>,
    data.startDate && data.endDate
      ? `${formatDate(data.startDate)} - ${formatDate(data.endDate)}`
      : undefined,
    data.state && states[data.state].name,
    data.city,
    data.attendance,
    <Rating readonly initialValue={data.rating} />,
  ];

  if (authUserType === 'ops') {
    values.push(data.company?.name);
  }

  values.push(
    <EventActionButton data={data} onEventOffer={onEventOffer} />,
    <RelativeDate date={data.updatedAt} />,
    data.lastEditor?.name
  );

  if (authUserType === 'client' && isCompanyUser) {
    values.push(<AssignTeamDialog event={data} />);
  }

  const isEventTeamLeader = data?.leaderId === authUser?.id;

  const isEventMember =
    isEventTeamLeader ||
    data?.teamMembers?.find((member) => member.id === authUser?.id);

  const isEventNeedSetup =
    !isEventTeamLeader && !data?.storage?.storageItems?.length;

  if (
    isEventMember &&
    !isCompanyUser &&
    dayjs().isSameOrAfter(data.startDate, 'day') &&
    dayjs().isSameOrBefore(data.endDate, 'day')
  ) {
    values.push(
      <Flex gap={4}>
        <Button
          text="Start"
          disabled={isEventNeedSetup}
          onClick={(e) => {
            removeDefaultPropagation(e, true);
            navigate(generatePath(AppRoutes.Products.Home, { id: data.id }));
          }}
        />
        {isEventNeedSetup && (
          <Popup
            on={['hover', 'focus']}
            position={[
              'bottom center',
              'bottom left',
              'top center',
              'top left',
            ]}
            trigger={
              <ButtonWithRef
                padding="0"
                transparent
                icon={<Icon.Info />}
                colorType={ButtonColors.BlackWhite}
              />
            }
          >
            <Styles.InfoContainer>
              The "Start" button is not yet available. You need to wait until
              the team manager adds items for sale to the event.
            </Styles.InfoContainer>
          </Popup>
        )}
      </Flex>
    );
  }

  return (
    <ListCard
      nativeIconColor
      values={values}
      headers={authUserType && titlesByUserType[authUserType]}
      template={authUserType && templateByUserType[authUserType]}
      onClick={() =>
        navigate(generatePath(AppRoutes.Events.Details, { id: data.id }))
      }
    />
  );
};
