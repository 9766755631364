import { lazy } from 'react';

import * as Users from './users';
import * as Events from './event';
import * as Profile from './profile';
import * as Company from './company';
import * as Products from './products';
import * as Dashboard from './dashboard';
import * as Customers from './customers';
import * as Employees from './employees';
import * as Accounting from './accounting';
import * as Deals from './inventory/deals';
import * as Category from './inventory/category';
import * as Storages from './inventory/storages';
import * as Suppliers from './inventory/suppliers';
import * as Attributes from './inventory/attributes';
import * as DailyCounts from './inventory/daily-counts';
import * as StockOrders from './shipments/stock-orders';
import * as EventOrders from './shipments/event-orders';
import * as InventoryProducts from './inventory/products';
import * as EventInventory from './inventory/event-inventory';
import * as StockTransfers from './inventory/stock-transfers';

const Reports = lazy(() => import('./reports'));
const Settings = lazy(() => import('./settings'));
const NotFound = lazy(() => import('./not-found'));
const Inventory = lazy(() => import('./inventory'));
const Shipments = lazy(() => import('./shipments'));
const Themes = lazy(() => import('./settings/themes'));
const Devices = lazy(() => import('./settings/devices'));
const Receipts = lazy(() => import('./settings/receipts'));
const CustomerCare = lazy(() => import('./customer-care'));
const Carriers = lazy(() => import('./settings/carriers'));
const Shipping = lazy(() => import('./settings/shipping'));
const Permissions = lazy(() => import('./settings/permissions'));
const Integrations = lazy(() => import('./settings/integrations'));
const BusinessInfo = lazy(() => import('./settings/business-info'));
const ProductTypes = lazy(() => import('./settings/product-types'));
const Notifications = lazy(() => import('./settings/notifications'));
const PaymentMethods = lazy(() => import('./settings/payment-methods'));

export {
  Users,
  Deals,
  Events,
  Themes,
  Profile,
  Reports,
  Devices,
  Company,
  Storages,
  Category,
  NotFound,
  Settings,
  Shipping,
  Products,
  Carriers,
  Receipts,
  Suppliers,
  Inventory,
  Shipments,
  Employees,
  Dashboard,
  Customers,
  Attributes,
  Accounting,
  DailyCounts,
  StockOrders,
  Permissions,
  EventOrders,
  BusinessInfo,
  ProductTypes,
  CustomerCare,
  Integrations,
  Notifications,
  StockTransfers,
  EventInventory,
  PaymentMethods,
  InventoryProducts,
};
