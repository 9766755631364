import { useParams, generatePath } from 'react-router-dom';

import { AppRoutes } from 'config';
import { mapQuery } from 'utils/query';
import { Flex } from 'components/flex';
import { List } from 'components/list';
import { isDesktop, useNavigate } from 'hooks';
import { ListCard } from 'components/list-card';
import { useListQuery } from 'store/api/inventory';
import { ListHeader } from 'components/list-header';
import { textValue, formatPrice } from 'utils/helpers';
import { Avatar, AvatarPlaceholderType } from 'components/avatar';

import * as Styles from './styles';
import { StorageInventoryPageProps } from './types';

const { InventoryDetails } = AppRoutes.Inventory.Storages.Details;

const titles = [
  'Product title',
  'SKU',
  'Current QTY',
  'Unit per case',
  'Cost price',
  'Min price',
  'Retail price',
];
const cellsMobile = [
  { row: '1/2', col: '1/3' },
  { row: '2/3', col: '1/2' },
  { row: '2/3', col: '2/3' },
];

export const StorageInventoryPage = ({
  productDetailsPath,
}: StorageInventoryPageProps) => {
  const { id } = useParams();
  const desktop = isDesktop();
  const navigate = useNavigate();
  const { data, ...props } = useListQuery(id!, { skip: !id });

  const getPrice = (price?: number) => (price ? formatPrice(price) : null);

  return (
    <List
      {...mapQuery(props)}
      hasMinHeight
      count={data?.length ?? 0}
      header={<ListHeader titles={titles} />}
      mainWrapperStyles={desktop ? undefined : { padding: 0 }}
    >
      {data?.map((value) => (
        <ListCard
          key={value.id}
          headers={titles}
          mobileTemplate="1fr 1fr"
          cellsMobile={cellsMobile}
          tabletTemplate="repeat(3, 1fr)"
          onClick={() =>
            navigate(
              generatePath(productDetailsPath || InventoryDetails, {
                inventoryId: value.productId,
              })
            )
          }
          values={[
            <Flex gap={8} alignItems="center">
              <Avatar
                square
                size={40}
                url={value?.product?.photoUrl}
                placeholder={AvatarPlaceholderType.GreyIcon}
              />
              <Flex flexDirection="column">
                <Styles.Title>{value?.product?.name}</Styles.Title>
                <Styles.Subtitle>
                  Unit weight: {textValue(value?.product?.unitWeight)}
                </Styles.Subtitle>
              </Flex>
            </Flex>,
            value?.product?.sku,
            <Flex flexDirection="column">
              {value?.count}

              <Styles.Subtitle>
                Cost:{' '}
                {formatPrice(
                  value?.count && value?.price && value.count * value.price
                )}
              </Styles.Subtitle>
            </Flex>,
            value?.product?.unitPerCase,
            getPrice(value?.product?.costPrice),
            getPrice(value?.product?.minPrice),
            getPrice(value?.product?.retailPrice),
          ]}
        />
      ))}
    </List>
  );
};
