import { EmptyPlaceholder } from 'config';
import { EllipsisText } from 'components/ellipsis-text';

import * as Styles from './styles';
import { InfoProps } from './types';

const Info = ({
  title,
  value,
  gap = 8,
  boldTitle,
  noEllipsis,
  valueClassName,
}: InfoProps): JSX.Element => {
  const titleNode = <Styles.Title boldTitle={boldTitle}>{title}</Styles.Title>;

  return (
    <Styles.Column gap={gap}>
      {typeof title === 'string' ? (
        <EllipsisText>{titleNode}</EllipsisText>
      ) : title ? (
        titleNode
      ) : null}

      {value ? (
        <div>
          {noEllipsis ? (
            value
          ) : (
            <EllipsisText className={valueClassName}>{value}</EllipsisText>
          )}
        </div>
      ) : (
        EmptyPlaceholder
      )}
    </Styles.Column>
  );
};

export { Info };
export { InfoBordered } from './bordered';
export type { InfoBorderedProps } from './bordered/types';
