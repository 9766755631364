import { Fragment, useContext } from 'react';

import { isDesktop } from 'hooks';
import { ThemeContext } from 'context';
import { ListCardStyle } from 'types/enums';
import { EllipsisText } from 'components/ellipsis-text';
import {
  textValue,
  generateTemplate,
  handleSelectionOnClick,
} from 'utils/helpers';

import * as Styles from './styles';
import { getCells } from './helpers';
import { ListCardCell, ListCardProps } from './types';

const ListCard = ({
  isOdd,
  footer,
  values,
  onClick,
  padding,
  headers,
  gap = 24,
  disabled,
  selected,
  className,
  rowGap = 16,
  innerFooter,
  templateRows,
  isPropagation,
  mobileTemplate,
  tabletTemplate,
  radius = '16px',
  innerFooterPadding,
  nativeIconColor = false,
  withPlaceholders = true,
  verticalAlign = 'center',
  odd = ListCardStyle.Grey,
  even = ListCardStyle.Default,
  template = generateTemplate(values.length),
  ...props
}: ListCardProps) => {
  const desktop = isDesktop();
  const { theme } = useContext(ThemeContext);
  const cells = getCells(props, theme);

  return (
    <>
      <Styles.Container
        odd={odd}
        even={even}
        isOdd={isOdd}
        radius={radius}
        disabled={disabled}
        selected={selected}
        className={className}
        isPropagation={isPropagation}
        nativeIconColor={nativeIconColor}
        onClick={handleSelectionOnClick(onClick)}
      >
        <Styles.Content
          gap={gap}
          cells={cells}
          rowGap={rowGap}
          padding={padding}
          template={template}
          templateRows={templateRows}
          verticalAlign={verticalAlign}
          mobileTemplate={mobileTemplate}
          tabletTemplate={tabletTemplate}
        >
          {values.map((value, idx) => {
            const renderValue =
              value &&
              typeof value !== 'string' &&
              typeof value !== 'number' ? (
                <Fragment key={idx}>{value}</Fragment>
              ) : (
                <EllipsisText key={idx}>
                  {withPlaceholders ? textValue(value) : value}
                </EllipsisText>
              );

            if (desktop || !headers) {
              return renderValue;
            }

            return (
              <Styles.MobileContainer key={idx}>
                <Styles.MobileTitle>{headers[idx]}</Styles.MobileTitle>
                {renderValue}
              </Styles.MobileContainer>
            );
          })}
        </Styles.Content>
        {innerFooter && (
          <Styles.InnerFooter innerFooterPadding={innerFooterPadding}>
            <Styles.InnerDivider />
            {innerFooter}
          </Styles.InnerFooter>
        )}
      </Styles.Container>
      {footer}
    </>
  );
};

export { ListCard };
export type { ListCardCell, ListCardProps };
