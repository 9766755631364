import { formatPrice } from 'utils/helpers';
import { useListQuery } from 'store/api/inventory';
import { SelectProductsFieldSection } from 'components';

export const StorageProductsSelectSection = ({
  storageId,
}: {
  storageId: number;
}) => {
  const { data: sourceStorageProducts } = useListQuery(String(storageId));

  return (
    <SelectProductsFieldSection
      name="items"
      products={
        sourceStorageProducts?.map(({ count, product }) => ({
          ...product,
          maxCount: count,
        })) ?? []
      }
      columns={[
        {
          position: 2,
          header: 'Count in storage',
          value: (product) => product?.maxCount,
        },
        {
          position: 3,
          header: 'Price',
          value: (product) => formatPrice(product?.costPrice),
        },
        {
          position: 4,
          header: 'Total',
          value: (_, totalPrice) => formatPrice(totalPrice),
        },
      ]}
    />
  );
};
