import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import dayjs, { Dayjs, ConfigType } from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';

import { joinStrings } from './helpers';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isYesterday);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormatPlugin);

export const formatDate = (date: ConfigType, format: string = 'L'): string => {
  return dayjs(date).format(format);
};

export const formatTimezoneSpecific = (
  date: ConfigType,
  timeZone?: string,
  template: string = 'L'
): string => {
  const isStringNumber = typeof date === 'string' && /^\d+$/.test(date);
  const dayjsValue = dayjs(isStringNumber ? Number(date) : date);

  return (timeZone ? dayjsValue.tz(timeZone) : dayjsValue).format(template);
};

export const formatTimezoneCode = (value: string) => {
  const parts = value.split('/');
  const partsCount = parts.length;
  if (partsCount < 2) {
    return value;
  }
  return `${value.split('/')[partsCount - 1].replaceAll('_', ' ')} (${value})`;
};

export const getTimezoneOptions = () =>
  Intl.supportedValuesOf('timeZone')
    .map((value) => ({
      value,
      label: formatTimezoneCode(value),
    }))
    .sort((a, b) => {
      const isAmericaA = a.value.startsWith('America');
      const isAmericaB = b.value.startsWith('America');
      if (isAmericaA && isAmericaB) {
        return a.label.localeCompare(a.label);
      }
      if (isAmericaA) {
        return -1;
      }
      if (isAmericaB) {
        return 1;
      }
      return a.value.localeCompare(b.value);
    });

export const getCalendarTime = (
  data: number | Dayjs,
  oldFormat = 'MM/DD/YY'
) => {
  const current = typeof data === 'number' ? dayjs.unix(data) : data;

  if (current.isToday()) {
    return 'Today';
  }
  if (current.isYesterday()) {
    return 'Yesterday';
  }

  const durationTime = Math.abs(dayjs().diff(current, 'day'));

  return current.format(durationTime <= 7 ? 'dddd' : oldFormat);
};

export const advancedFormat = (date: string | Dayjs): string => {
  const dayjsObj = dayjs(date);
  const isDateToday = dayjsObj.isToday();
  const isDateYesterday = dayjsObj.isYesterday();

  const template = `${
    isDateToday ? '[Today], ' : isDateYesterday ? '[Yesterday], ' : ''
  }D MMMM - hh:mm a (z)`;

  return dayjsObj.format(template);
};

export const getDuration = (
  date: number,
  unit: DurationUnitType = 'second',
  finished?: number
): number => {
  const now = Date.now();
  const isEnded = finished && finished <= now;
  const durationValue = Math.max(isEnded ? finished - date : now - date, 0);
  const value = dayjs.duration(durationValue, 'millisecond');
  return value.as(unit);
};

export const getTimeValue = (value: number) => {
  return value < 10 ? `0${value}` : value;
};

export const formatDuration = (
  value: number,
  format: (duration: duration.Duration) => string = (durationValue) => {
    const minutes = Math.floor(durationValue.asMinutes());
    const seconds = durationValue.seconds();

    return `${getTimeValue(minutes)}:${getTimeValue(seconds)}`;
  },
  unit: DurationUnitType = 'second'
) => {
  return format(dayjs.duration(value, unit)!);
};

export const getAge = (milliseconds?: string | number | null) => {
  if (!milliseconds) {
    return [];
  }
  const age = dayjs.duration(Date.now() - Number(milliseconds));

  const years = age.years();
  const months = age.months();
  const days = age.days();

  return [years, months, days];
};

export const getFormattedAge = (
  value?: string | null,
  placeholder?: Parameters<typeof joinStrings>[2]
) => {
  const units = getAge(value);
  const suffixes = ['y', 'm', 'd'];

  const components = units.map((unit, index) =>
    !unit ? undefined : `${unit}${suffixes[index]}`
  );

  return joinStrings(components, ' ', placeholder);
};

export const formatTimeData = (value: number, format: string = 'h:mm a') => {
  const currentDay = dayjs().startOf('d');
  return currentDay.add(dayjs.duration(value, 'ms')).format(format);
};

export const getTimeData = (date: number | string | Date | Dayjs) => {
  const currentDay = dayjs(date);
  const startOfDay = currentDay.startOf('d');
  return dayjs.duration(currentDay.diff(startOfDay)).asMilliseconds();
};

export const getNumberOfWeek = (date: Date) => {
  const day = dayjs(date);
  const dayOfMonth = day.date();
  const weekNumber = Math.ceil(dayOfMonth / 7);
  return weekNumber;
};

export const toLocaleISOString = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
