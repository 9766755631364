import { useNavigate } from 'hooks';
import { EmptyPlaceholder } from 'config';
import { Product } from 'store/api/product/types';
import { Flex, Avatar, ListCard, EllipsisText } from 'components';

import { DeleteProductDialog } from '../delete-product-dialog';

import { titles } from './config';

export const Card = ({ data }: { data: Product }) => {
  const navigate = useNavigate();

  const values = [
    <Flex gap={16} alignItems="center">
      <Avatar size={56} url={data.photoUrl} />
      <EllipsisText className="semibold">{data.name}</EllipsisText>
    </Flex>,
    data.sku,
    data.supplier?.name,
    EmptyPlaceholder,
    data.costPrice,
    data.minPrice,
    data.retailPrice,
    EmptyPlaceholder,
    EmptyPlaceholder,
    <DeleteProductDialog id={data.id} name={data.name} />,
  ];

  return (
    <ListCard
      values={values}
      headers={titles}
      onClick={() => navigate(String(data.id))}
    />
  );
};
