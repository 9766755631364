import dayjs from 'dayjs';

import { useNavigate } from 'hooks';
import { formatPhone } from 'utils/helpers';
import { Flex, Avatar, ListCard } from 'components';

import { CardProps } from './types';
import { titles, cellsMobile } from './config';

export const Card = ({ data, getDetailsPath }: CardProps) => {
  const navigate = useNavigate();

  return (
    <ListCard
      headers={titles}
      cellsMobile={cellsMobile}
      onClick={() => navigate(getDetailsPath?.(data.id) || String(data.id))}
      values={[
        <Flex gap={16} alignItems="center">
          <Avatar size={56} url={data.person.photoUrl} />
          <p>
            {data.person.firstName} {data.person.lastName}
          </p>
        </Flex>,
        data.employee.name,
        data.event?.name,
        formatPhone(data.person.phone),
        data.person.email,
        data.registered,
        data.person.birthday && dayjs(data.person.birthday).format('LL'),
      ]}
    />
  );
};
